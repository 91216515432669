import React from 'react';
import { MdErrorOutline } from "react-icons/md";


const ErrorPage = (props) => {
    return (
        <div className="bg-white flex flex-col justify-center items-center grow rounded-lg p-4 gap-y-4 h-full">
            <MdErrorOutline className="w-1/3 h-1/3 text-fuchsia" />
            <p className="font-bold">An error has occured, please try again later.</p>
        </div>);
}

export default ErrorPage;
