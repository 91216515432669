import { LoadingPage, ErrorPage, Title } from "components";
import {
  BsChevronLeft,
  BsChevronBarLeft,
  BsChevronRight,
  BsChevronBarRight,
} from "react-icons/bs";
import ProjectDetail from "./ProjectDetail";
import { count_project, list_projects } from "api";
import { useState } from "react";
import useSWR from "swr";
import { PageHelp } from "@/components/styled/pageHelp";
import CreateNewProject from "./CreateNewProject";

const page_button =
  "enabled:text-black rounded-md enabled:hover:text-fuchsia disabled:text-gray-400";

export default function Home() {
  const numProjects = 10;

  // What page the user has selected.
  // 0-Indexed internally, but displayed as 1-Indexed to user
  const [page, setPage] = useState(0);

  const {
    data: countProjects,
    isLoading: isCountProjectsLoading,
    error: isCountProjectsError,
  } = useSWR("count_project", () => {
    return count_project({});
  });

  const {
    data: projects,
    isLoading: isProjectsLoading,
    error: isProjectsError,
  } = useSWR(`list_projects/${page}`, () => {
    return list_projects({
      start_number: page * numProjects,
      num_projects: numProjects,
    });
  });

  if (isCountProjectsLoading || isProjectsLoading) return <LoadingPage />;
  if (isCountProjectsError || isProjectsError) return <ErrorPage />;

  const totalPages = Math.ceil((countProjects?.count || 0) / numProjects);

  const SetFirstPage = () => setPage(0);
  const SetLastPage = () => setPage(totalPages - 1);
  const DecrementPage = () => setPage((v) => Math.max(v - 1, 0));
  const IncrementPage = () => setPage((v) => Math.min(v + 1, totalPages - 1));

  return (
    <div className="bg-white flex flex-col grow rounded-lg h-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Projects" />
        <div className="flex gap-x-2 items-center">
          <CreateNewProject />
          <PageHelp
            title="Project Overview"
            content={
              <div>
                This is the Home Page that shows all of the different projects
                you are a part of. Each project is an area to work on refining
                your idea and to collaborate with others to create the best
                Grant Proposal possible. From here you can also navigate to the
                profile where you can update your information to help us
                recommend better calls for you, update your billing information,
                or see the calls that you have bookmarked. You can also go to
                the Guidelines Assistant Tab if you need help.
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-col grow border-2 rounded-t-xl shadow-md overflow-y-auto overflow-x-none">
        <div className="bg-offblack grid grid-cols-8 text-white rounded-t-lg p-2 gap-x-2 text-xl">
          <div className="col-span-4">Name</div>
          <div className="col-span-2">Owned By</div>
          <div className="col-span-2">Creation Date</div>
        </div>
        <div className="flex flex-col overflow-y-auto overflow-x-hidden grow ">
          {typeof projects === "string" || (projects?.length || 0) === 0 ? (
            <p className="text-center p-2">You currently have no projects.</p>
          ) : (
            projects.map((project, i) => <ProjectDetail key={i} {...project} />)
          )}
        </div>
      </div>

      <div className="flex items-center justify-center gap-x-4 font-bold">
        <button
          disabled={totalPages === 0 || page === 0}
          className={page_button}
          onClick={SetFirstPage}
        >
          <BsChevronBarLeft className="stroke-1" />
        </button>
        <button
          disabled={totalPages === 0 || page === 0}
          className={page_button}
          onClick={DecrementPage}
        >
          <BsChevronLeft className="stroke-1" />
        </button>
        <div className="gap-x-2 flex">
          {totalPages === 0 ? 0 : page + 1}
          <span>of</span>
          <span>{totalPages}</span>
        </div>
        <button
          disabled={totalPages === 0 || page === totalPages - 1}
          className={page_button}
          onClick={IncrementPage}
        >
          <BsChevronRight className="stroke-1" />
        </button>
        <button
          disabled={totalPages === 0 || page === totalPages - 1}
          className={page_button}
          onClick={SetLastPage}
        >
          <BsChevronBarRight className="stroke-1" />
        </button>
      </div>
    </div>
  );
}
