import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  add_brainstorm_ideas,
  generate_brainstorm_ideas_from_session,
} from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import Idea from "./Idea";

export default function GenerateIdeas({ projectId, sessionId }) {
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [generatedIdeas, setGeneratedIdeas] = useState([]);

  const {
    trigger: generatedBrainstormIdeasFromSession,
    isMutating: isGeneratingIdeas,
  } = useSWRMutation(
    "generate_brainstorm_ideas_from_session",
    () => generate_brainstorm_ideas_from_session({ session_id: sessionId }),
    {
      onSuccess: (d) => {
        setGeneratedIdeas(d);
      },
    }
  );

  const {
    isMutating: isAddingGeneratedIdeas,
    error: addGenerateIdeasError,
    trigger: addGeneratedIdeas,
  } = useSWRMutation(
    `update_brainstorm_ideas`,
    () =>
      add_brainstorm_ideas({
        project_id: projectId,
        brainstorm_ideas: selectedIdeas,
        ai_generated: true,
      }),
    {
      onSuccess: () => {
        mutate(`/project/idea/brainstorm/${projectId}`);

        setGeneratedIdeas((ideas) =>
          ideas.filter(
            ({ item, description }) =>
              selectedIdeas.findIndex(
                (selectedIdea) =>
                  selectedIdea.item === item &&
                  selectedIdea.description === description
              ) === -1
          )
        );
        setSelectedIdeas([]);
      },
    }
  );

  const handleToggleIdea = ({ item, description }) => {
    setSelectedIdeas((prevIdeas) =>
      selectedIdeas.findIndex(
        (idea) => idea.item === item && idea.description === description
      ) === -1
        ? [...prevIdeas, { item, description }]
        : prevIdeas.filter(
            (idea) => !(idea.item === item && idea.description === description)
          )
    );
  };

  const handleAddToGeneratedIdeas = ({ index, newItems }) => {
    setGeneratedIdeas((previousIdeas) => [
      ...previousIdeas.slice(0, index + 1),
      ...newItems,
      ...previousIdeas.slice(index + 1),
    ]);
  };

  return (
    <Dialog>
      <DialogTrigger className="btn btn-primary">Generate Ideas</DialogTrigger>
      <DialogContent className=" max-w-3xl">
        <DialogHeader>
          <DialogTitle>Generate Ideas</DialogTitle>
          <DialogDescription>
            Generate and add brainstorming ideas from your session.
          </DialogDescription>
        </DialogHeader>
        {generatedIdeas?.length > 0 && (
          <>
            <ScrollArea className="max-h-80">
              {generatedIdeas?.ideas?.length === 0 && (
                <span>There are no more generated ideas</span>
              )}
              {isAddingGeneratedIdeas ? (
                <span className="flex items-center gap-x-2">
                  <LoadingSpinner /> Generating ideas. This may take a few
                  seconds...
                </span>
              ) : addGenerateIdeasError ? (
                <span>
                  There was an error generating ideas. Please try again.
                </span>
              ) : (
                <div className="flex flex-col gap-y-2 p-1">
                  {generatedIdeas?.map((idea, i) => {
                    const isSelected = selectedIdeas.find(
                      (e) =>
                        e.item === idea.item &&
                        e.description === idea.description
                    );
                    return (
                      <Idea
                        handleAddToGeneratedIdeas={handleAddToGeneratedIdeas}
                        projectId={projectId}
                        handleToggleIdea={handleToggleIdea}
                        {...idea}
                        index={i}
                        key={i}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </div>
              )}
            </ScrollArea>
            <div className="flex justify-between">
              <DialogClose className="hover:underline">Close</DialogClose>
              <button
                className="btn btn-primary"
                disabled={!selectedIdeas.length || isAddingGeneratedIdeas}
                onClick={addGeneratedIdeas}
              >
                Add ({selectedIdeas.length})
                {isAddingGeneratedIdeas && <LoadingSpinner />}
              </button>
            </div>
          </>
        )}

        {isGeneratingIdeas && (
          <div className="border-2 border-dashed rounded-lg flex justify-center items-center p-4">
            <div className="flex gap-x-2 items-center justify-center">
              Generating ideas <LoadingSpinner />
            </div>
          </div>
        )}
        {!generatedIdeas?.length && !isGeneratingIdeas && (
          <div className="border-dashed border-2 flex flex-col justify-center items-center rounded-lg p-4 gap-y-4">
            <button
              className="btn btn-primary"
              onClick={generatedBrainstormIdeasFromSession}
            >
              Generate Brainstorm Ideas
            </button>
            <span className="text-base">Note: this may take a few seconds</span>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
