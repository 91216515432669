import { expand_brainstorm_idea } from "api";
import { LoadingSpinner } from "components";
import { SproutIcon } from "lucide-react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import useSWRMutation from "swr/mutation";

export default function Idea({
  projectId,
  item,
  description,
  handleToggleIdea,
  handleAddToGeneratedIdeas,
  index,
  isSelected,
}) {
  const {
    trigger: expandBrainstormIdea,
    isMutating: isExpandingBrainstormIdea,
  } = useSWRMutation(
    "expand_brainstorm_idea",
    (_, { arg }) =>
      expand_brainstorm_idea({ project_id: projectId, idea: arg }),
    {
      onSuccess: (d) => {
        handleAddToGeneratedIdeas({ index: index, newItems: d.ideas });
      },
    }
  );

  const handleExpandIdea = ({ item, description }) => {
    expandBrainstormIdea({ item: item, description: description });
  };
  return (
    <div className="border-b-1 pb-2">
      <button
        onClick={() =>
          handleToggleIdea({
            description: description,
            item: item,
          })
        }
        className={
          "flex flex-col group text-left text-black ring-fuchsia hover:ring-2 hover:bg-light-pink p-2 rounded-md " +
          (isSelected && "bg-light-pink")
        }
      >
        <span className="group-hover:underline font-bold inline-flex items-center">
          {isSelected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          {item}
        </span>
        <span className="">{description}</span>
      </button>
      <button
        disabled={isExpandingBrainstormIdea}
        className="hover:text-fuchsia flex items-center gap-x-2 text-sm hover:cursor-pointer"
        onClick={() =>
          handleExpandIdea({
            description: description,
            item: item,
          })
        }
      >
        {isExpandingBrainstormIdea ? (
          <LoadingSpinner />
        ) : (
          <SproutIcon className="h-5 w-5" />
        )}
        Expand Similar Ideas
      </button>
    </div>
  );
}
