import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { delete_paper } from "api";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function DeletePaper({
  projectId,
  paper_id,
  title,
  handleOnClickCancel,
}) {
  const {
    isMutating: isRemovingPaper,
    error: removePaperError,
    trigger: removePaper,
  } = useSWRMutation(
    `remove_paper/${projectId}/${paper_id}`,
    () => delete_paper({ project_id: projectId, paper_id: paper_id }),
    {
      onSuccess: () => {
        mutate(
          `get_papers?${projectId}`,
          (data) => data.filter((d) => d.paper_id !== paper_id),
          { revalidate: false }
        );
      },
    }
  );
  return (
    <>
      <DialogHeader>
        <DialogTitle>Remove {title}</DialogTitle>
        <DialogDescription>
          Are you sure you want to remove this paper?
        </DialogDescription>
      </DialogHeader>
      <div className="flex justify-between">
        <button className="btn-secondary" onClick={handleOnClickCancel}>
          Cancel
        </button>
        <button className="btn-primary" onClick={removePaper}>
          Remove
        </button>
      </div>
    </>
  );
}
