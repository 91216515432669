import { remove_timestamp } from "@/lib/utils";
import { create_checkout_session, create_customer_portal } from "api/billing";
import { LoadingSpinner } from "components";
import { TriangleAlertIcon } from "lucide-react";
import useSWRMutation from "swr/mutation";

export default function CurrentPlan({
  monthlyPrice,
  customerPlan,
  org_relationship,
}) {
  const { trigger: createCustomerPortal } = useSWRMutation(
    "create_customer_portal",
    () => create_customer_portal(),
    {
      onSuccess: ({ url }) => window.open(url, "_blank"),
    }
  );

  const { trigger: createCheckoutSession, isMutating: isCheckingOut } =
    useSWRMutation(
      "create_checkout_session",
      (_, { arg }) => create_checkout_session({ price_id: arg }),
      { onSuccess: ({ url }) => window.open(url) }
    );

  return (
    <div className="flex justify-center p-4">
      <div className="border-[1px] max-w-3xl w-full rounded-md p-4 flex flex-col gap-y-4">
        <div>
          <div className="flex justify-between items-center">
            <div className="font-semibold text-lg text-gray-500 capitalize">
              Current Plan
            </div>
            {(org_relationship === "admin" || org_relationship === "owner") && (
              <>
                {customerPlan.prompt_upgrade ? (
                  <button
                    className="text-sm border-[1px] rounded-md p-1 hover:border-fuchsia"
                    onClick={() => createCheckoutSession(monthlyPrice.id)}
                    disabled={isCheckingOut}
                  >
                    {isCheckingOut ? (
                      <span className="flex items-center gap-x-2">
                        <LoadingSpinner /> Upgrading
                      </span>
                    ) : (
                      <span>Upgrade</span>
                    )}
                  </button>
                ) : (
                  <button
                    className="text-sm border-[1px] rounded-md p-1 hover:border-fuchsia"
                    onClick={() => createCustomerPortal(monthlyPrice.id)}
                  >
                    <span>
                      {customerPlan?.cancel_at_period_end
                        ? "Renew Plan"
                        : "Manage Plan"}
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
          <div>
            <span className="capitalize">{customerPlan.tier}</span>
          </div>
        </div>

        {customerPlan.tier === "pro" && (
          <div>
            <div className="font-semibold text-lg text-gray-500">
              Next Billing Date
            </div>
            {customerPlan?.cancel_at_period_end ? (
              <div className="flex w-full text-base border-yellow-500 border-2 p-2 rounded-lg bg-yellow-100 items-center gap-x-4">
                <TriangleAlertIcon className="h-8 w-8" />
                <span>
                  Your membership will expire on{" "}
                  {remove_timestamp(customerPlan.current_period_end)}. We're
                  sorry to see you go and hope to welcome you back in the
                  future!{" "}
                  <span
                    className="hover:underline text-fuchsia hover:cursor-pointer"
                    onClick={() => createCustomerPortal(monthlyPrice.id)}
                  >
                    Renew your membership here.
                  </span>
                </span>
              </div>
            ) : customerPlan?.current_period_end ? (
              remove_timestamp(customerPlan.current_period_end)
            ) : (
              "Not Available"
            )}
          </div>
        )}
        {customerPlan.tier === "pro trial" && (
          <div>
            {" "}
            <div className="font-semibold text-lg text-gray-500">
              Trial End Date
            </div>
            {remove_timestamp(customerPlan.current_period_end)}
          </div>
        )}
      </div>
    </div>
  );
}
