import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TableCell, TableRow } from "@/components/ui/table";
import { remove_timestamp } from "@/lib/utils";
import {
  create_followup_session,
  create_new_brainstorm_session,
  delete_session,
} from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { TiArrowForward } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechanical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Education", value: "education" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function SessionItem({
  projectId,
  session_id,
  creation_time,
  finished,
  title,
  finishedSessions,
  hasEditorAccess,
}) {
  const navigate = useNavigate();

  const [dialogMode, setDialogMode] = useState("create"); // Create or Delete modal

  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent2Background, setAgent2Background] = useState("");
  const [followupSessionId, setFollowupSessionId] = useState("new");
  const [isProjectGrounded, setIsProjectGrounded] = useState(false);

  const {
    isMutating: isCreatingBrainstormSession,
    error: createBrainstormSessionError,
    trigger: createBrainstormSession,
  } = useSWRMutation(
    `create_brainstorm_session`,
    () => {
      if (followupSessionId === "new") {
        return create_new_brainstorm_session({
          project_id: projectId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
          project_grounded: isProjectGrounded,
        });
      } else {
        return create_followup_session({
          project_id: projectId,
          session_id: followupSessionId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
          project_grounded: isProjectGrounded,
        });
      }
    },
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate((key) =>
          key?.startsWith(`get_brainstorm_sessions/${projectId}`)
        );
      },
    }
  );

  const {
    isMutating: isDeletingSession,
    error: deleteSessionError,
    trigger: deleteSession,
  } = useSWRMutation(
    `delete_session`,
    () =>
      delete_session({
        session_id: session_id,
      }),
    {
      onSuccess: () => {
        mutate(
          (key) =>
            key?.startsWith(`get_brainstorm_sessions/${projectId}`) ||
            key === `get_finished_brainstorm_sessions/${projectId}`,
          undefined,
          {
            revalidate: true,
          }
        );
      },
    }
  );

  return (
    <TableRow key={session_id} className="group">
      <TableCell
        className="hover:text-fuchsia hover:underline hover:cursor-pointer"
        onClick={() =>
          navigate(
            `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
          )
        }
      >
        {title}
      </TableCell>
      <TableCell>{finished ? "Done" : "In Progress"}</TableCell>
      <TableCell>{remove_timestamp(creation_time)}</TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Dialog>
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!hasEditorAccess} className="disabled:hidden">
              <FaEllipsisV className="hover:text-fuchsia" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem asChild>
                <DialogTrigger
                  className="flex gap-x-2"
                  onClick={() => setDialogMode("create")}
                >
                  <TiArrowForward className="h-4 w-4" />
                  Start Followup Session
                </DialogTrigger>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <DialogTrigger
                  className="flex gap-x-2 w-full"
                  onClick={() => setDialogMode("delete")}
                >
                  <MdDelete className="h-4 w-4" />
                  Delete
                </DialogTrigger>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {dialogMode === "create" ? (
            <DialogContent className="max-w-lg">
              <DialogHeader>
                <DialogTitle>
                  Create a Followup Session from "{title}"
                </DialogTitle>
                <DialogDescription>
                  Start from where you left off.
                </DialogDescription>
              </DialogHeader>
              <div className="flex flex-col gap-y-4">
                <Input
                  placeholder="Session Title"
                  value={sessionTitle}
                  onChange={(e) => setSessionTitle(e.target.value)}
                />

                <Select
                  value={agent1Background}
                  onValueChange={setAgent1Background}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Researcher 1 Expertise" />
                  </SelectTrigger>
                  <SelectContent>
                    {agent_types.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select
                  value={agent2Background}
                  onValueChange={setAgent2Background}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Researcher 2 Expertise" />
                  </SelectTrigger>
                  <SelectContent>
                    {agent_types.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select
                  options={[
                    { label: "Start from scratch", value: "new" },
                    ...finishedSessions.map(({ title, session_id }) => ({
                      label: title,
                      value: session_id,
                    })),
                  ]}
                  value={followupSessionId}
                  onChange={setFollowupSessionId}
                  placeholder="Start a new session"
                />
                <div className="flex items-center gap-x-2">
                  <Checkbox
                    checked={isProjectGrounded}
                    onCheckedChange={(c) => setIsProjectGrounded(c)}
                    id="project-grounded"
                  />
                  <label htmlFor="project-grounded">
                    Keep conversation grounded to project
                  </label>
                </div>
                <div>
                  <button
                    onClick={createBrainstormSession}
                    disabled={!agent1Background || !agent2Background}
                    className="btn-fuchsia text-base"
                  >
                    Start Session
                  </button>
                </div>
              </div>
            </DialogContent>
          ) : (
            <DialogContent className="max-w-lg">
              <DialogHeader>
                <DialogTitle>Delete "{title}"</DialogTitle>

                <DialogDescription>
                  Are you sure you want to delete this session?
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-between text-sm">
                <DialogClose className="hover:underline">Close</DialogClose>
                {isDeletingSession ? (
                  <span className="flex gap-x-2 items-center text-red-500">
                    Deleting <LoadingSpinner />
                  </span>
                ) : (
                  <button
                    className="text-red-500 hover:underline"
                    onClick={deleteSession}
                  >
                    Delete
                  </button>
                )}
              </div>
            </DialogContent>
          )}
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
