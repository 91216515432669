import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { unbookmark_project_call, unbookmark_user_call } from "api";
import { LoadingSpinner } from "components";
import { BookmarkIcon, ExternalLinkIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Call({
  agency,
  description,
  project_id,
  project_name,
  call_id,
  close_date,
  posted_date,
  title,
  url,
}) {
  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmarkCall,
  } = useSWRMutation(
    `unbookmark_user_call`,
    () =>
      project_id
        ? unbookmark_project_call({ project_id: project_id, call_id: call_id })
        : unbookmark_user_call({
            call_id: call_id,
          }),
    {
      onSuccess: () => {
        mutate((key) => key?.startsWith("search_calls"), undefined, {
          revalidate: true,
        });
        mutate("get_user_bookmarked_calls", undefined, { revalidate: true });
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger>
        <div className="flex flex-col border-[1px] rounded-lg p-4 gap-x-4 hover:border-fuchsia text-left">
          <div>{project_name || "No Attached Project"}</div>
          <div>
            <span className="text-2xl font-semibold">{title}</span>
          </div>
          <span>{agency}</span>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <div className="flex gap-x-2">
          <button
            className="btn-secondary"
            disabled={isUnbookmarking}
            onClick={unbookmarkCall}
          >
            Unbookmark
            {isUnbookmarking ? (
              <LoadingSpinner />
            ) : (
              <BookmarkIcon className="w-4 h-4" />
            )}
          </button>
          <Link
            className="btn-secondary hover:opacity-60 hover:underline"
            to={`/project/${project_id}`}
            onClick={(e) => e.stopPropagation()}
          >
            Open Project
          </Link>
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="btn-secondary hover:opacity-60 hover:underline"
            >
              Open URL <ExternalLinkIcon className="w-4 h-4" />
            </a>
          )}
        </div>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div>{agency}</div>
        {posted_date && <div>{posted_date}</div>}
        {close_date && <div>{close_date}</div>}
        {description}...
      </DialogContent>
    </Dialog>
  );
}
