import { ErrorPage, LoadingPage, LoadingSpinner, Title } from "components";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { useState } from "react";
import { search_calls, search_similar_calls } from "api";
import SearchBar from "./SearchBar";
import SuggestedCall from "./SuggestedCall";
import { Input } from "@/components/ui/input";
import Select from "react-select";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { DatePicker } from "@/components/ui/datepicker";
import { format } from "date-fns";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Link, useParams } from "react-router-dom";
import { PageHelp } from "@/components/styled/pageHelp";
import { Switch } from "@/components/ui/switch";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDownIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useIsEditor } from "@/hooks/use-is-editor";

const fundingOptions = [
  { value: "ACA", label: "Affordable Care Act" },
  { value: "AG", label: "Agriculture" },
  { value: "AR", label: "Arts" },
  { value: "BC", label: "Business and Commerce" },
  { value: "CD", label: "Community Development" },
  { value: "CP", label: "Consumer Protection" },
  { value: "DPR", label: "Disaster Prevention and Relief" },
  { value: "ED", label: "Education" },
  { value: "ELT", label: "Employment, Labor and Training" },
  { value: "EN", label: "Energy" },
  { value: "ENV", label: "Environment" },
  { value: "FN", label: "Food and Nutrition" },
  { value: "HL", label: "Health" },
  { value: "HO", label: "Housing" },
  { value: "HU", label: "Humanities" },
  { value: "ISS", label: "Income Security and Social Services" },
  { value: "IS", label: "Information and Statistics" },
  { value: "LJL", label: "Law, Justice and Legal Services" },
  { value: "NR", label: "Natural Resources" },
  { value: "RA", label: "Recovery Act" },
  { value: "RD", label: "Regional Development" },
  {
    value: "ST",
    label: "Science and Technology and other Research and Development",
  },
  { value: "T", label: "Transportation" },
  { value: "O", label: "Other" },
];

export default function SearchCalls() {
  const { projectId } = useParams();

  const [calls, setCalls] = useState(null);

  const [promptToUpgrade, setPromptToUpgrade] = useState(false);

  const [query, setQuery] = useState("");
  const [grantFloor, setGrantFloor] = useState(0);
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [costSharing, setCostSharing] = useState(false);
  const [fundingCategory, setFundingCategory] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    isMutating: isLoadingCalls,
    error: callsError,
    trigger: getCalls,
  } = useSWRMutation(
    `search_calls/${projectId}`,
    () =>
      search_calls({
        project_id: parseInt(projectId),
        query: query,
        min_award_floor: grantFloor,
        cost_sharing: costSharing,
        closing_date: closingDate,
        category_of_funding:
          fundingCategory?.length > 0
            ? fundingCategory.map((c) => c.value)
            : undefined,
      }),
    {
      onSuccess: (calls) => {
        setCalls(calls);
        if (calls.hasOwnProperty("credits_used")) {
          mutate(
            `get_settings`,
            (d) => ({
              ...d,
              credits_left: d.credits_left - calls.credits_used,
            }),
            {
              revalidate: false,
            }
          );
        }
      },
      onError: (err) => {
        if (err.status === 422) {
          setPromptToUpgrade(true);
        }
      },
      throwOnError: false,
    }
  );

  const {
    isMutating: isSearchingSimilarCalls,
    error: searchSimilarCallsError,
    trigger: searchSimilarCalls,
  } = useSWRMutation(
    `search_similar_calls`,
    (_, { arg }) =>
      search_similar_calls({
        call_id: arg,
        project_id: parseInt(projectId),
      }),
    {
      onSuccess: (calls) => {
        setCalls(calls);
        if (calls.hasOwnProperty("credits_used")) {
          mutate(
            `get_settings`,
            (d) => ({
              ...d,
              credits_left: d.credits_left - calls.credits_used,
            }),
            {
              revalidate: false,
            }
          );
        }
        mutate(`search_calls/${query}`, () => calls, { revalidate: false });
      },
      onError: (err) => {
        if (err.status === 422) {
          setPromptToUpgrade(true);
        }
      },
      throwOnError: false,
    }
  );

  const onSearch = (q) => {
    setIsFiltersOpen(false);
    setQuery(q);
    getCalls();
  };

  const handleClosingDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setClosingDate(formattedDate);
  };

  if (isEditorLoading) return <LoadingPage />;
  if (editorError) return <ErrorPage />;
  if (!hasEditorAccess)
    return (
      <p>
        Sorry, you do not have access to search calls for this project. Please
        contact the owner of this project to get access.
      </p>
    );

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Search Calls" />
        <PageHelp
          title="Grant Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Grant Space is dedicated to discovering and managing funding
                opportunities.
              </div>
              <div>
                <h2 className="font-semibold">Recommendations</h2>
                Using the information you provided in the Research Idea and
                Background Material sections of the Ideation Space, we will
                suggest funding opportunities we think may be relevant to you.
              </div>
              <div>
                <h2 className="font-semibold">Search</h2>A search tool for
                finding calls.
              </div>
              <div>
                <h2 className="font-semibold">Bookmarks </h2>
                Keep track of important calls by bookmarking them. This section
                shows all calls bookmarked by you and any other collaborators on
                the project.
              </div>
            </div>
          }
        />
      </div>
      <SearchBar query={query} setQuery={setQuery} onSubmit={onSearch} />
      <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sorry, you are out of credits!</DialogTitle>
            <DialogDescription>
              Please upgrade to our pro tier to continue using this feature.
              Your credits will also refresh on the first of every month.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center">
            <Link
              className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
              to="/settings/billing"
            >
              Upgrade to Pro
            </Link>
          </div>
        </DialogContent>
      </Dialog>
      <Collapsible open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
        <CollapsibleTrigger className="flex items-center hover:text-fuchsia underline mb-4">
          {isFiltersOpen ? "Hide Filters" : "Show Filters"}{" "}
          <ChevronDownIcon
            data-open={isFiltersOpen}
            className="transition-transform data-[open=true]:rotate-180"
          />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <div className="flex flex-col w-40">
              <Tooltip>
                <TooltipTrigger showIcon={true} className="text-left">
                  <span className="text-base underline">Min. Award:</span>
                </TooltipTrigger>
                <TooltipContent className="max-w-xl">
                  We will only search for calls that have an award that is
                  higher than the value you provide here.
                </TooltipContent>
              </Tooltip>
              <Input
                className="w-full pl-1"
                type="number"
                value={grantFloor}
                onChange={(e) => setGrantFloor(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-base underline">
                Earliest Closing Date:
              </span>
              <DatePicker value={closingDate} onChange={handleClosingDate} />
            </div>
            <div className="flex flex-col">
              <Tooltip>
                <TooltipTrigger showIcon={true}>
                  <span className="text-base underline">Cost Sharing:</span>
                </TooltipTrigger>
                <TooltipContent className="max-w-xl">
                  This filter indicates if you want to search for calls with a
                  Cost Sharing or Matching requirement. If turned off, we will
                  only search for ones without. If turned on, we will search for
                  either.
                </TooltipContent>
              </Tooltip>
              <Switch
                checked={costSharing}
                onClick={() => {
                  setCostSharing((p) => !p);
                }}
              ></Switch>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <Tooltip>
                  <TooltipTrigger showIcon={true}>
                    <span className="text-base underline">
                      Funding Category:
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xl">
                    This filter will only search for calls if they belong to any
                    of the selected categories. Leave blank if you would like to
                    search for any category.
                  </TooltipContent>
                </Tooltip>
              </div>
              <Select
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    outlineWidth: "2",
                    outlineStyle: "solid",
                    zIndex: 50,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    transition: "transition",
                    overflowX: "scroll",
                    maxHeight: state.isFocused ? "100%" : "2.5em",
                    overflowY: "scroll",
                    maxWidth: "100%",
                    borderColor: state.isFocused ? "fuchsia" : "zinc-300",
                  }),
                }}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(newValue) => setFundingCategory(newValue)}
                options={fundingOptions}
                value={fundingCategory}
                placeholder={"Leave empty to search all categories"}
              />
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>

      {isLoadingCalls || isSearchingSimilarCalls ? (
        <div className="flex flex-col items-center justify-center">
          <LoadingSpinner className="text-fuchsia h-20 w-20" />
        </div>
      ) : (
        calls?.calls?.length > 0 && (
          <ScrollArea className="p-4">
            <div className="flex flex-col gap-y-8">
              {calls.calls.map((call) => (
                <SuggestedCall
                  key={call.call_id}
                  {...call}
                  query={query}
                  projectId={projectId}
                  searchSimilarCalls={searchSimilarCalls}
                  setCalls={setCalls}
                />
              ))}
            </div>
          </ScrollArea>
        )
      )}
    </div>
  );
}
