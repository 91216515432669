import {
  get_project,
  get_profile,
  get_required_files,
  add_required_file,
} from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import File from "./File";
import useSWRMutation from "swr/mutation";
import { PageHelp } from "@/components/styled/pageHelp";
import AddRecommendedFiles from "./AddRecommendedFiles";
import { useEffect, useState } from "react";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function DocumentChecklist() {
  const { projectId } = useParams();

  const [sortedCompletedFiles, setSortedCompletedFiles] = useState([]);
  const [sortedIncompletedFiles, setSortedIncompletedFiles] = useState([]);

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: requiredFiles,
    isLoading: isRequiredFilesLoading,
    error: requiredFilesError,
  } = useSWR(`get_required_files/${projectId}`, () =>
    get_required_files({ project_id: projectId })
  );

  const { error: addRequiredFileError, trigger: addRequiredFile } =
    useSWRMutation(
      `add_required_file`,
      () => add_required_file({ project_id: projectId }),
      {
        onSuccess: () => {
          mutate(`get_required_files/${projectId}`);
        },
      }
    );

  useEffect(() => {
    // TODO: sort by creation time like action items
    setSortedCompletedFiles(
      requiredFiles?.filter(({ completed }) => completed) || []
    );
    setSortedIncompletedFiles(
      requiredFiles?.filter(({ completed }) => !completed) || []
    );
  }, [requiredFiles]);

  if (isRequiredFilesLoading || isEditorLoading) return <LoadingPage />;
  if (requiredFilesError || editorError) return <ErrorPage />;

  const tabs = [
    {
      label: "All",
      value: "all",
      content: [...sortedIncompletedFiles, ...sortedCompletedFiles].map(
        (file) => (
          <File
            key={file.file_id}
            {...file}
            projectId={projectId}
            hasEditorAccess={hasEditorAccess}
          />
        )
      ),
    },
    {
      label: "Pending",
      value: "pending",
      content: [...sortedIncompletedFiles].map((file) => (
        <File
          key={file.file_id}
          {...file}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
    {
      label: "Completed",
      value: "completed",
      content: [...sortedCompletedFiles].map((file) => (
        <File
          key={file.file_id}
          {...file}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
  ];
  return (
    <div className="bg-white flex flex-col grow rounded-lg h-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Document Checklist" />
        <PageHelp
          title="Admin Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Admin Space facilitates collaboration and ensures timely
                submission of your grant application.
              </div>
              <div>
                <h2 className="font-semibold">Selected Call</h2>
                View all details about the grant you are applying for. If you
                haven't selected a call yet, you can do so here.
              </div>
              <div>
                <h2 className="font-semibold">Action Items</h2>
                Manage tasks with a to-do list for each project. Create action
                items, assign them to users, set deadlines, and receive email
                reminders for pending tasks.
              </div>
              <div>
                <h2 className="font-semibold">Required Files</h2>
                Track all necessary files and their statuses in one place. Once
                a grant is selected in the Grant Space, we can also recommend
                which files are needed, reducing the time spent on managing
                document requirements.
              </div>
            </div>
          }
        />
      </div>
      {hasEditorAccess && (
        <AddRecommendedFiles
          projectId={projectId}
          requiredFiles={requiredFiles}
        />
      )}
      <Table>
        <colgroup>
          <col className="w-10"></col>
          <col className=""></col>
        </colgroup>
        <TableCaption className="text-left border-y-[1px]">
          {hasEditorAccess && (
            <button
              className="w-full text-left py-2 transition-colors hover:text-black hover:bg-gray-100"
              onClick={addRequiredFile}
            >
              + Add a new file
            </button>
          )}
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-fit">Complete</TableHead>
            <TableHead>File</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{tabs[0].content}</TableBody>
      </Table>
    </div>
  );
}
