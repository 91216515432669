import React, { useEffect, useRef, useState } from "react";
import InitiumChatMessage from "./InitiumChatMessage";
import UserChatMessage from "./UserChatMessage";
import useSWRMutation from "swr/mutation";
import {
  get_brainstorm_session_messages,
  send_brainstorm_session_message,
} from "api";
import { ErrorPage, LoadingSpinner } from "components";
import { BsFillSendFill } from "react-icons/bs";
import useSWR from "swr";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Mention, MentionsInput } from "react-mentions";

const CustomInput = React.forwardRef((props, ref) => {
  return <Input ref={ref} {...props} />;
});

const SuggestionContainer = ({ props }) => {
  console.log(props.children);
  return (
    <ul>
      {props.children.map(({ key, props }) => (
        <li>{props.suggestion.display}</li>
      ))}
    </ul>
  );
};

export default function ChatWindow({
  creator_name,
  sessionInfo,
  projectId,
  sessionId,
  finished,
  requestor_is_creator,
}) {
  const messagesPerPage = 15;
  const chatWindow = useRef(null);
  const bottomRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isReplyToOpen, setIsReplyToOpen] = useState(false);

  const previousScrollHeight = useRef(0);
  const [page, setPage] = useState(0);
  const [numMessagesSent, setNumMessagesSent] = useState(0); // Used to get previous messages correctly
  const [isLastPage, setIsLastPage] = useState(false); // Bool to check if we reached the last page

  const [startAgent, setStartAgent] = useState("");

  const {
    data: initialBrainstormMessages,
    isLoading: isGettingBrainstormSessionsMessages,
    error: brainstormSessionsMessageError,
  } = useSWR(
    isLastPage ? null : `messages?session_id=${sessionId}&page=${page}`,
    () =>
      get_brainstorm_session_messages({
        session_id: sessionId,
        start_number: page * messagesPerPage + numMessagesSent,
      }),
    {
      onSuccess: (messages) => {
        if (messages.length < 15) setIsLastPage(true);
        setDisplayedMessages((m) => [...messages.toReversed(), ...m]);
      },
    }
  );

  const [displayedMessages, setDisplayedMessages] = useState([
    ...(initialBrainstormMessages || []).toReversed(),
  ]);

  const {
    isMutating: isSendingMessage,
    error: sendMessageError,
    trigger: sendMessage,
  } = useSWRMutation(
    `send_brainstorm_session_message`,
    () =>
      send_brainstorm_session_message({
        session_id: sessionId,
        message: message
          .replaceAll("@[Researcher_1](1)", "")
          .replaceAll("@[Researcher_2](2)", ""),
        start_agent: startAgent ? startAgent : null,
      }),
    {
      onSuccess: ({ message_id, messages }) => {
        setNumMessagesSent((num) => num + messages.length);
        setDisplayedMessages((previous_messages) => [
          ...previous_messages.slice(0, previous_messages.length - 1),
          {
            id: message_id,
            ...previous_messages[previous_messages.length - 1],
          },
          ...messages,
        ]);
      },
    }
  );

  const handleScroll = () => {
    if (chatWindow?.current?.scrollTop === 0 && !isLastPage) {
      previousScrollHeight.current = chatWindow.current.scrollHeight;
      setPage((page) => page + 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSendingMessage) return;

    setNumMessagesSent((num) => num + 1);
    sendMessage(message);
    setDisplayedMessages((messages) => [
      ...messages,
      { message: message, sender: creator_name },
    ]);
    setMessage("");
  };

  useEffect(() => {
    if (
      startAgent &&
      !message.includes("@[Researcher_1](1)") &&
      !message.includes("@[Researcher_2](2)")
    ) {
      setStartAgent("");
    }
  }, [message]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSendingMessage]);

  useEffect(() => {
    if (page === 0 && bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [displayedMessages]);

  if (brainstormSessionsMessageError) return <ErrorPage />;

  return (
    <div className="h-full flex flex-col overflow-y-auto">
      <div
        className="flex justify-center items-center gap-x-2 aria-hidden:hidden"
        aria-hidden={!isGettingBrainstormSessionsMessages}
      >
        <LoadingSpinner /> Loading previous messages...
      </div>
      <div className="relative flex grow h-full overflow-y-auto shrink">
        <div
          ref={chatWindow}
          onScroll={handleScroll}
          className="grow flex flex-col gap-y-4 overflow-y-scroll p-4"
        >
          {displayedMessages.map((message, index) =>
            message.sender.startsWith("Researcher") ? (
              <InitiumChatMessage
                projectId={projectId}
                key={index}
                {...message}
                background={
                  message.sender === "Researcher 1"
                    ? sessionInfo.agent_1_background
                    : sessionInfo.agent_2_background
                }
                creator_name={creator_name}
                sessionId={sessionId}
                finished={finished}
              />
            ) : (
              <UserChatMessage
                key={index}
                {...message}
                className="bg-gray-200"
              />
            )
          )}

          {isSendingMessage && (
            <div className="flex gap-x-2 items-center justify-center">
              Retrieving messages <LoadingSpinner />
            </div>
          )}
          <div ref={bottomRef}></div>
        </div>
      </div>
      {!finished && requestor_is_creator && (
        <>
          {" "}
          <DropdownMenu
            open={isReplyToOpen}
            onOpenChange={(v) => setIsReplyToOpen(v)}
          >
            <DropdownMenuTrigger />
            <DropdownMenuContent>
              <DropdownMenuItem>Researcher 1</DropdownMenuItem>
              <DropdownMenuItem>Researcher 2</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <form className="flex w-full gap-x-2 p-2" onSubmit={handleSubmit}>
            <MentionsInput
              className="w-full border-1 rounded-lg"
              style={{
                "&multiLine": {
                  highlighter: {
                    padding: 9,
                  },
                  input: {
                    padding: 9,
                  },
                },

                suggestions: {
                  item: {
                    "&focused": {
                      color: "fuchsia",
                    },
                  },
                },
              }}
              inputRef={CustomInput}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              forceSuggestionsAboveCursor={true}
              customSuggestionsContainer={(children) => (
                <div className="border-1 p-2">{children}</div>
              )}
              allowSpaceInQuery={true}
            >
              <Mention
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@${display}`}
                className="bg-pastel-fuchsia rounded-lg"
                trigger={startAgent ? "@?@?@!" : "@"}
                onAdd={(id) => setStartAgent(id)}
                data={[
                  { id: 1, display: "Researcher_1" },
                  { id: 2, display: "Researcher_2" },
                ]}
              />
            </MentionsInput>

            <button
              disabled={message.length === 0 || isSendingMessage}
              className="btn-fuchsia flex gap-x-2 items-center"
            >
              Send
              {isSendingMessage ? <LoadingSpinner /> : <BsFillSendFill />}
            </button>
          </form>
        </>
      )}
    </div>
  );
}
