import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { get_file, get_file_review_comments } from "api/review_space";
import { ErrorPage, LoadingPage } from "components";
import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import useSWR from "swr";
import CommentChat from "./CommentChat";

export default function Comments({ fileId }) {
  const [filterByReviewer, setFilterByReviewer] = useState("Reviewer 1");
  const iFrameRef = useRef(null);

  const {
    data: comments,
    isLoading: isGettingComments,
    error: commentsError,
  } = useSWR(`get_file_review_comments/${fileId}`, () =>
    get_file_review_comments({ file_id: fileId })
  );

  if (isGettingComments) return <LoadingPage />;
  if (commentsError) return <ErrorPage />;

  const { reviewer_feedback, url } = comments;

  const isPDF = url?.includes(".pdf");
  const encodedBucketUrl = encodeURIComponent(url);
  // If PDF, we use google doc viewer. If docx, use microsoft office web viewer
  const encodedUrl = isPDF
    ? url
    : `https://view.officeapps.live.com/op/embed.aspx?src=${encodedBucketUrl}&embedded=true`;



  return (
    <div className="flex w-full h-full gap-x-2 pb-8 pt-4">
      <iframe
        onLoad={(e)=>console.log(e)}
        ref={iFrameRef}
        className="h-full w-full"
        src={encodedUrl}
        height="100%"
        width="100%"
      />

      <div className="flex flex-col h-full text-sm w-6/12 gap-y-2">
        <Select
          value={filterByReviewer}
          onValueChange={(v) => setFilterByReviewer(v)}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {[
              { label: "Reviewer 1", value: "Reviewer 1" },
              { label: "Reviewer 3", value: "Reviewer 3" },
              { label: "All", value: "All" },
            ].map(({ label, value }) => (
              <SelectItem value={value} key={value}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <ScrollArea>
          <div className="flex flex-col h-full gap-y-16 text-sm overflow-visible">
            {[
              reviewer_feedback.map((feedback, i) => (
                <div
                  className="bg-beige p-2 rounded-md relative"
                  key={`${feedback.reviewer}+${feedback.section_id}`}
                  hidden={
                    filterByReviewer !== "All" &&
                    filterByReviewer !== feedback.reviewer
                  }
                >
                  <CommentChat
                    fileId={fileId}
                    reviewer={feedback.reviewer}
                    sectionID={feedback.section_id}
                    title={feedback.title}
                  />
                  <div className="text-lg">{feedback.reviewer}</div>
                  <div>{feedback.title}</div>
                  <div>Page {feedback.page}</div>
                  <Markdown>{feedback.feedback}</Markdown>
                </div>
              )),
            ]}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
