import { Title } from "components";
export default function FileSummary({ summary }) {
  return (
    <div className="flex flex-col overflow-y-scroll mb-8 gap-y-12">
      <div>
        <Title className="text-2xl" value="Summary" />
        <div className="text-base">{summary}</div>
      </div>
    </div>
  );
}
