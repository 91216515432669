import { Outlet, redirect } from "react-router-dom";

import InitiumLogo from "assets/initium-logos/primary-horizontal-colored-black.svg";
import { useAuthStore } from "stores/useAuthStore";
import { mutate } from "swr";

// On load for any login layout, check if  user is already logged in.
// If already logged in, redirect to home page.
export const LoginLayoutLoader = async () => {
  let refreshToken = useAuthStore.getState().refreshToken;
  if (refreshToken) {
    return redirect("/");
  }
  // Clear any cache on entering login page.
  // Useful for clearing cache when logging out
  mutate(() => true, undefined, { revalidate: true });
  return null;
};

export default function LoginLayout() {
  return (
    <div className="h-screen w-screen bg-offwhite flex justify-center">
      <div className="w-full flex flex-col justify-between">
        <img
          className="w-40 aspect-auto"
          src={InitiumLogo}
          alt="Initium Logo"
        />
        <Outlet />
        <div className="h-20"></div>
      </div>
    </div>
  );
}
