import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Title } from "components";
import Ideas from "./ideas/Ideas";
import Sessions from "./session-list/Sessions";
import { PageHelp } from "@/components/styled/pageHelp";
import { useParams, useSearchParams } from "react-router-dom";

export default function Brainstorm() {
  const [searchQuery] = useSearchParams();
  return (
    <div className="flex flex-col h-full gap-y-2">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Brainstorm" />

        <PageHelp
          title="Ideation Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Ideation Space is designed to help you refine your research
                idea.
              </div>
              <div>
                <h2 className="font-semibold">Research Idea</h2>
                Here you will tell us a more about what idea you would like to
                get funded.
              </div>
              <div>
                <h2 className="font-semibold">Background Material</h2>
                Enhance our understanding of your idea by searching for and
                linking relevant papers. You can search for papers using Arxiv
                or Semantic Scholar. Note: adding papers here will help us to
                recommend better calls for you in the future.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Ideas</h2>
                Use this section to further develop your idea, take notes, and
                explore new perspectives. You can manually add ideas to the
                brainstorming list, or let us generate suggestions for you.
                Ideas are generated based on your Research Idea, Description,
                and any Background Materials you've provided.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Sessions</h2>
                With Sessions, you can easily create brainstorming rooms with
                experts from various backgrounds. Each session lasts a few days,
                and after it ends, we'll provide a summary of the discussion.
                You can start new sessions based on previous topics or begin
                with a fresh idea.
              </div>
            </div>
          }
        />
      </div>
      <Tabs defaultValue={searchQuery.get("tab") || "ideas"}>
        <div>
          <TabsList>
            <TabsTrigger value="ideas">Ideas</TabsTrigger>
            <TabsTrigger value="sessions">Sessions</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="ideas">
          <Ideas />
        </TabsContent>
        <TabsContent value="sessions">
          <Sessions />
        </TabsContent>
      </Tabs>
    </div>
  );
}
