import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { create_project } from "api";
import { LoadingSpinner } from "components";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function CreateNewProject() {
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const {
    isMutating: isCreatingProject,
    error: creatingProjectError,
    trigger: createProject,
  } = useSWRMutation(
    `create_project`,
    () => create_project({ name: projectName }),

    {
      onSuccess: (response) => {
        const { project_id } = response;
        mutate("count_project", undefined, { revalidate: false });
        mutate((key) => key?.startsWith("list_projects"), undefined, {
          revalidate: false,
        });
        setProjectName("");
        navigate(`/project/${project_id}`);
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger className="btn-fuchsia">
        <PlusIcon /> Create new project
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a new project</DialogTitle>
          <DialogDescription></DialogDescription>
          <Input
            placeholder="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <div className="flex justify-end">
            {isCreatingProject ? (
              <button
                className="btn-fuchsia"
                disabled={isCreatingProject}
                onClick={createProject}
              >
                <LoadingSpinner /> Creating
              </button>
            ) : (
              <button
                className="btn-fuchsia"
                disabled={!projectName}
                onClick={createProject}
              >
                Create
              </button>
            )}
          </div>
          {creatingProjectError && (
            <span className="text-red-500">
              Something went wrong, please try again.
            </span>
          )}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
