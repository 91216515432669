import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/lib/utils";
import { HelpCircleIcon } from "lucide-react";
import { FaQuestionCircle } from "react-icons/fa";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

/**
 * showIcon {boolean}
 */
const TooltipTrigger = React.forwardRef(({ showIcon, ...props }, ref) => (
  <TooltipPrimitive.Trigger {...props} ref={ref}>
    <>
      {props.children} {showIcon && <FaQuestionCircle className="inline" />}
    </>
  </TooltipPrimitive.Trigger>
));

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipContent = React.forwardRef(
  ({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-50 rounded-md bg-popover px-3 py-1.5 text-sm text-popover-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 border-1 shadow-xl max-w-2xl",
        className
      )}
      {...props}
    />
  )
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipArrow,
};
