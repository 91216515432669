import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { create_template } from "api/write_space";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function CreateNewTemplate({ projectId }) {
  const [templateName, setTemplateName] = useState("");
  const navigate = useNavigate();
  const {
    isMutating: isCreatingNewTemplate,
    error: createNewTemplateError,
    trigger: createNewTemplate,
  } = useSWRMutation(
    `create_new_template`,
    () =>
      create_template({
        project_id: projectId,
        template_name: templateName,
      }),
    {
      onSuccess: ({ template_id }) => {
        mutate(`/write/template/list?${projectId}`);
        navigate(`/project/${projectId}/write-space/${template_id}`);
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger className="btn-fuchsia text-base">
        Create Template
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Create a new template</DialogTitle>
        <DialogDescription>
          Enter a template name to create a new template outline.
        </DialogDescription>
        <Input
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="New Template"
        />
        <div className="flex justify-end">
          <button
            className="btn-fuchsia text-base"
            disabled={!templateName}
            onClick={createNewTemplate}
          >
            Create
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
