import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";
import useSWRMutation from "swr/mutation";
import { add_heading, rerank_heading } from "api/write_space";
import { ScrollArea } from "@/components/ui/scroll-area";
import { mutate } from "swr";
import { Accordion } from "@/components/ui/accordion";

export default function SortableList({
  headers,
  templateId,
  projectId,
  headerLabels,
  isInitialOutlineGeneration,
}) {
  const [items, setItems] = useState(
    [...headers].map((header) => ({ ...header, id: header.heading_id }))
  );

  const [value, setValue] = useState(null); // Tracks what heading is open

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { trigger: addNewHeader } = useSWRMutation(
    "add_new_header",
    () =>
      add_heading({
        template_id: templateId,
        depth: 0,
        heading: "",
        rank_position: { rank_at_end: true },
      }),
    {
      onSuccess: () => {
        mutate(`/write/template/${projectId}/${templateId}`, (d) => d, {
          revalidate: true,
        });
      },
    }
  );

  const { trigger: moveHeader } = useSWRMutation(
    "rerank_heading",
    async (key, { arg: { heading_id, newIndex, newArray } }) => {
      if (newIndex === items.length - 1) {
        // Moved to end
        await rerank_heading({
          template_id: templateId,
          heading_id: heading_id,
          rank_position: {
            rank_at_end: true,
          },
        });
      } else if (newIndex === 0) {
        // Moved to beginnning
        const next_heading_id = newArray[newIndex + 1].id;
        await rerank_heading({
          template_id: templateId,
          heading_id: heading_id,
          rank_position: {
            rank_at_end: false,
            prev_heading_id: null,
            next_heading_id: next_heading_id,
          },
        });
      } else {
        // Moved to somewhere in the middle
        const prev_heading_id = newArray[newIndex - 1].id;
        const next_heading_id = newArray[newIndex + 1].id;
        await rerank_heading({
          template_id: templateId,
          heading_id: heading_id,
          rank_position: {
            rank_at_end: false,
            prev_heading_id: prev_heading_id,
            next_heading_id: next_heading_id,
          },
        });
      }
      return newArray;
    },
    {
      onSuccess: (newArray) => {
        mutate(`/write/template/${projectId}/${templateId}`, newArray, {
          revalidate: false,
        });
      },
    }
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newArray = arrayMove(items, oldIndex, newIndex);
        moveHeader({
          heading_id: active.id,
          newIndex: newIndex,
          newArray: newArray,
        });

        return newArray;
      });
    }
  }

  useEffect(() => {
    setItems(
      [...headers].map((header) => ({ ...header, id: header.heading_id }))
    );
  }, [headers]);

  return (
    <ScrollArea className="h-full">
      <div className="h-full flex flex-col gap-y-2 p-2 pb-16">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <Accordion
              type="single"
              collapsible
              value={value}
              className="flex flex-col gap-y-4"
            >
              {items.map((item, i) => (
                <SortableItem
                  {...item}
                  key={item.heading_id}
                  id={item.heading_id}
                  templateId={templateId}
                  projectId={projectId}
                  label={headerLabels[i]}
                  next_heading_id={
                    i < items.length - 1 ? items[i + 1].heading_id : null
                  }
                  value={value}
                  setValue={setValue}
                  isInitialOutlineGeneration={isInitialOutlineGeneration}
                />
              ))}
            </Accordion>
          </SortableContext>
        </DndContext>
        <div className="flex w-full">
          <button
            className="border-2 border-dashed text-base rounded-md w-full p-1 hover:border-fuchsia"
            onClick={addNewHeader}
          >
            Add a new header
          </button>
        </div>
      </div>
    </ScrollArea>
  );
}
