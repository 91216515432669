import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  MdArrowLeft,
  MdArrowRight,
  MdDelete,
  MdOutlineDragIndicator,
} from "react-icons/md";
import { Input } from "@/components/ui/input";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { add_heading, edit_heading, remove_heading } from "api/write_space";
import { AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Textarea } from "@/components/ui/textarea";
import { MinusIcon, PlusIcon } from "lucide-react";
export function SortableItem({
  heading,
  heading_id,
  depth,
  projectId,
  templateId,
  outline_guidance,
  label,
  isInitialOutlineGeneration,
  next_heading_id,
  value,
  setValue,
  ...props
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [tempHeading, setTempHeading] = useState(heading);
  const [tempDepth, setTempDepth] = useState(depth);
  const [tempOutlineGuidance, setTempOutlineGuidance] = useState(
    outline_guidance || ""
  );

  const { trigger: updateHeading } = useSWRMutation(
    "edit_heading",
    () =>
      edit_heading({
        template_id: templateId,
        project_id: projectId,
        heading_id: heading_id,
        heading: tempHeading,
      }),
    {
      onSuccess: () =>
        mutate(
          `/write/template/${projectId}/${templateId}`,
          (d) =>
            d.map((header) =>
              header.heading_id === heading_id
                ? { ...header, heading: tempHeading }
                : header
            ),
          { revalidate: false }
        ),
    }
  );

  const { trigger: updateOutlineGuidance } = useSWRMutation(
    "edit_outline_guidance",
    () =>
      edit_heading({
        template_id: templateId,
        project_id: projectId,
        heading_id: heading_id,
        outline_guidance: tempOutlineGuidance,
      }),
    {
      onSuccess: () =>
        mutate(
          `/write/template/${projectId}/${templateId}`,
          (d) =>
            d.map((header) =>
              header.heading_id === heading_id
                ? { ...header, outline_guidance: tempOutlineGuidance }
                : header
            ),
          { revalidate: false }
        ),
    }
  );

  const { trigger: updateDepth } = useSWRMutation(
    "edit_heading",
    async (key, { arg }) => {
      await edit_heading({
        template_id: templateId,
        project_id: projectId,
        heading_id: heading_id,
        depth: arg,
      });
      return arg;
    },
    {
      onSuccess: (newDepth) =>
        mutate(
          `/write/template/${projectId}/${templateId}`,
          (d) =>
            d.map((header) =>
              header.heading_id === heading_id
                ? { ...header, depth: newDepth }
                : header
            ),
          { revalidate: false }
        ),
    }
  );

  const { trigger: deleteHeading } = useSWRMutation(
    "delete_heading",
    () => remove_heading({ template_id: templateId, heading_id: heading_id }),
    {
      onSuccess: () =>
        mutate(
          `/write/template/${projectId}/${templateId}`,
          (d) => d.filter((header) => header.heading_id !== heading_id),
          { revalidate: false }
        ),
    }
  );

  const { trigger: addNewHeader } = useSWRMutation(
    "add_new_header",
    () =>
      add_heading({
        template_id: templateId,
        depth: tempDepth,
        heading: "",
        rank_position: next_heading_id
          ? {
              prev_heading_id: heading_id,
              next_heading_id: next_heading_id,
              rank_at_end: false,
            }
          : { rank_at_end: true },
      }),
    {
      onSuccess: () => {
        mutate(`/write/template/${projectId}/${templateId}`, (d) => d, {
          revalidate: true,
        });
      },
    }
  );

  const handleBlur = () => {
    if (tempHeading !== heading) {
      updateHeading();
    }
  };

  const handleUpdateDepth = (newDepth) => {
    setTempDepth(newDepth);
    updateDepth(newDepth);
  };

  const handleBlurOutlineGuidance = () => {
    if (tempOutlineGuidance !== outline_guidance) {
      if (!tempOutlineGuidance && !outline_guidance) return;
      updateOutlineGuidance();
    }
  };

  const handleDelete = () => {
    deleteHeading();
  };

  const handleAddNewSection = () => {
    addNewHeader();
  };
  return (
    <div ref={setNodeRef} style={style} type="single">
      <div
        className="flex items-start gap-x-2"
        style={{ marginLeft: tempDepth * 10 }}
      >
        <button className="pt-3.5" {...attributes} {...listeners}>
          <MdOutlineDragIndicator />
        </button>
        <div className="flex flex-col w-full gap-y-2">
          <AccordionItem
            className="border-b-0 p-1 flex flex-col w-full rounded-md"
            value={heading_id}
          >
            <div className="flex w-full gap-x-2">
              <span className="text-base pt-2">{label}</span>
              <div className="flex flex-col w-full">
                <div className="px-2">
                  <Input
                    value={tempHeading}
                    onChange={(e) => setTempHeading(e.target.value)}
                    onBlur={handleBlur}
                    onFocus={() => setValue(heading_id)}
                  />
                </div>
                <AccordionContent className="pt-2 pb-0 px-2">
                  {isInitialOutlineGeneration && (
                    <Textarea
                      value={tempOutlineGuidance}
                      placeholder="Enter initial outline guidance"
                      onChange={(e) => setTempOutlineGuidance(e.target.value)}
                      onBlur={handleBlurOutlineGuidance}
                    />
                  )}

                  <div className="flex justify-between">
                    <div className="flex gap-x-4">
                      <button
                        className="disabled:opacity-50 enabled:hover:underline flex items-center"
                        onClick={() => handleUpdateDepth(tempDepth - 1)}
                        disabled={tempDepth === 0}
                      >
                        <MdArrowLeft />
                        Remove Subsection
                      </button>
                      <button
                        className="flex items-center enabled:hover:underline"
                        onClick={() => handleUpdateDepth(tempDepth + 1)}
                      >
                        Add Subsection
                        <MdArrowRight />
                      </button>
                    </div>
                    <button
                      className={
                        "text-red-600 hover:underline flex items-center"
                      }
                      onClick={handleDelete}
                    >
                      Delete <MdDelete />
                    </button>
                  </div>
                </AccordionContent>
              </div>
              <div>
                <button
                  onClick={() =>
                    setValue((v) => (v !== heading_id ? heading_id : null))
                  }
                  className="hover:text-fuchsia pt-2"
                >
                  {value === heading_id ? (
                    <MinusIcon className="w-4 h-4" />
                  ) : (
                    <PlusIcon className="w-4 h-4" />
                  )}
                </button>
              </div>
            </div>
          </AccordionItem>
          <button
            className="border-dashed border-2 rounded-md text-base overflow-hidden opacity-0 max-h-0 hover:opacity-100 text-center hover:max-h-fit hover:-mt-2 hover:border-fuchsia transition-colors duration-500"
            onClick={handleAddNewSection}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}
