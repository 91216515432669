import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  delete_brainstorm_idea,
  expand_brainstorm_idea,
  update_brainstorm_idea,
} from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Idea({
  projectId,
  brainstorm_id,
  ai_generated,
  description,
  item,
  index,
  hasEditorAccess,
  handleAddToGeneratedIdeas,
}) {
  const [tempItem, setTempItem] = useState(item);
  const [tempDescription, setTempDescription] = useState(description);

  const {
    isMutating: isDeleting,
    error: isDeleteError,
    trigger: deleteBrainstormIdea,
  } = useSWRMutation(
    `delete_brainstorm_idea`,
    () =>
      delete_brainstorm_idea({
        project_id: projectId,
        brainstorm_id: brainstorm_id,
      }),
    {
      onSuccess: () => {
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => {
            return {
              ...data,
              items: data.items.filter(
                (d) => d.brainstorm_id !== brainstorm_id
              ),
            };
          },
          { revalidate: false }
        );
      },
    }
  );

  const { trigger: updateBrainstormIdea } = useSWRMutation(
    "update_brainstorm_idea",
    () =>
      update_brainstorm_idea({
        project_id: projectId,
        brainstorm_idea: {
          brainstorm_id: brainstorm_id,
          description: tempDescription,
          item: tempItem,
        },
      }),
    {
      onSuccess: () =>
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => ({
            ...data,
            items: data.items.map((idea) =>
              idea.brainstorm_id === brainstorm_id
                ? { ...idea, description: tempDescription, item: tempItem }
                : idea
            ),
          }),
          { revalidate: false }
        ),
    }
  );

  const {
    trigger: expandBrainstormIdea,
    isMutating: isExpandingBrainstormIdea,
  } = useSWRMutation(
    "expand_brainstorm_idea",
    (_, { arg }) =>
      expand_brainstorm_idea({ project_id: projectId, idea: arg }),
    {
      onSuccess: (d) => {
        handleAddToGeneratedIdeas({ index: index, newItems: d.ideas });
      },
    }
  );

  const handleExpandIdea = ({ item, description }) => {
    expandBrainstormIdea({ item: item, description: description });
  };

  return (
    <Dialog>
      <DialogTrigger
        className="text-left px-1 ring-fuchsia enabled:hover:ring-1 rounded-lg"
        disabled={!hasEditorAccess}
      >
        <div>
          {index}. {item}
        </div>
        <div className="text-sm">{description}</div>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Edit Idea</DialogTitle>
          <DialogDescription>
            Edit your idea or description in the fields below.
          </DialogDescription>
        </DialogHeader>
        <div>
          <span className="font-semibold">Idea</span>
          <Input
            className=""
            value={tempItem}
            onChange={(e) => setTempItem(e.target.value)}
          />
        </div>
        <div>
          <span className="font-semibold">Description</span>
          <Textarea
            className="min-h-40"
            value={tempDescription}
            onChange={(e) => setTempDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-between">
          <button
            className="btn hover:underline text-red-500"
            onClick={deleteBrainstormIdea}
            disabled={isDeleting}
          >
            Delete {isDeleting ? <LoadingSpinner /> : <MdDelete />}
          </button>
          <button
            disabled={tempItem === item && tempDescription === description}
            className="btn-fuchsia"
            onClick={updateBrainstormIdea}
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
