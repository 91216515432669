import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { add_batch_headings, add_heading } from "api/write_space";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const presetTemplates = [
  {
    id: "NSF",
    headings: [
      "Project Summary",
      "Introduction and Motivation",
      "Intellectual Merit",
      "Technical Background",
      "Previous Work by the PIs",
      "Research Plan",
      "Related Work",
      "Work Plan",
      "Broader Impacts",
      "Results from Previous NSF Support",
    ],
  },
  {
    id: "NIH",
    headings: [
      "Project Summary",
      "Specific Aims",
      "Research Strategy",
      "Significance ",
      "Innovation",
      "Approach",
      "Expertise of research team members",
      "Timeline, project management, and dissemination",
    ],
  },
];

export default function ChoosePreset({ templateId, projectId }) {
  const [presetTemplateId, setPresetTemplateId] = useState("NSF");
  const { trigger: addNewHeader } = useSWRMutation(
    "add_new_header",
    () =>
      add_heading({
        template_id: templateId,
        depth: 0,
        heading: "",
        rank_position: { rank_at_end: true },
      }),
    {
      onSuccess: () => {
        mutate(`/write/template/${projectId}/${templateId}`, (d) => d, {
          revalidate: true,
        });
      },
    }
  );

  const { trigger: addPresetTemplate } = useSWRMutation(
    "add_preset_template",
    () =>
      add_batch_headings({
        template_id: templateId,
        headings: presetTemplates
          .find(({ id }) => id === presetTemplateId)
          .headings.map((heading) => ({
            heading: heading,
          })),
      }),
    {
      onSuccess: () => {
        mutate(`/write/template/${projectId}/${templateId}`, (d) => d, {
          revalidate: true,
        });
      },
    }
  );

  return (
    <div className="flex flex-col text-center items-center grow justify-center">
      <Dialog>
        <DialogTrigger className="btn-fuchsia">
          Choose an agency template
        </DialogTrigger>
        <DialogContent className="max-h-screen">
          <DialogHeader>
            <DialogTitle>Choose an agency template</DialogTitle>
            <DialogDescription>
              Select a template below to see what sections will be added.
            </DialogDescription>
            <Tabs orientation="vertical" value={presetTemplateId}>
              <TabsList>
                {presetTemplates.map(({ id }) => (
                  <TabsTrigger
                    value={id}
                    key={id}
                    onClick={() => setPresetTemplateId(id)}
                  >
                    {id}
                  </TabsTrigger>
                ))}
              </TabsList>
              <ScrollArea>
                {presetTemplates.map(({ id, headings }) => (
                  <TabsContent className="text-base" value={id} key={id}>
                    <ul>
                      {headings.map((heading, i) => (
                        <li key={i}>{heading}</li>
                      ))}
                    </ul>
                  </TabsContent>
                ))}

                <div className="flex justify-end">
                  <button
                    className="btn-fuchsia text-base"
                    onClick={addPresetTemplate}
                  >
                    Create
                  </button>
                </div>
              </ScrollArea>
            </Tabs>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <span>or</span>
      <div>
        <button onClick={addNewHeader} className="hover:text-fuchsia">
          Start from a blank template
        </button>
      </div>
    </div>
  );
}
