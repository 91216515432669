import { get_brainstorm_sessions } from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import useSWR from "swr";

import { PageHelp } from "@/components/styled/pageHelp";
import SessionItem from "./SessionItem";

import CreateNewBrainstormSession from "./CreateNewBrainSession";
import { get_settings } from "api/settings";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function Sessions() {
  const { projectId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const num_sessions_per_page = 15;
  const page = Math.max(1, searchParams.get("page"));

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const {
    data: brainstormSessions,
    isLoading: isGettingBrainstormSessions,
    error: brainstormSessionsError,
    isValidating: isValidatingSessions,
  } = useSWR(`get_brainstorm_sessions/${projectId}page=${page}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: (page - 1) * num_sessions_per_page,
      num_session: num_sessions_per_page,
    })
  );

  const {
    data: finishedSessions,
    isLoading: isGettingFinishedSessions,
    error: finishedSessionsError,
    isValidating: isValidatingFinishedSessions,
  } = useSWR(`get_finished_brainstorm_sessions/${projectId}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: 0,
      num_session: 15,
      finished: true,
    })
  );

  if (
    isEditorLoading ||
    isGettingSettings ||
    isGettingBrainstormSessions ||
    isGettingFinishedSessions ||
    isValidatingSessions ||
    isValidatingFinishedSessions
  )
    return <LoadingPage />;
  if (
    editorError ||
    settingsError ||
    brainstormSessionsError ||
    finishedSessionsError
  )
    return <ErrorPage />;

  const isLastPage = brainstormSessions?.length < num_sessions_per_page;
  const increasePagination = () => {
    if (!isLastPage) setSearchParams({ page: page + 1 });
  };
  const decreasePagination = () => {
    if (page !== 1) setSearchParams({ page: Math.max(1, page - 1) });
  };

  return (
    <>
      <div className="text-base">
        Welcome to Sessions — your brainstorming copilot. Easily create rooms
        with experts from various fields, with each session lasting just a few
        days. When it's over, we'll provide a quick recap. Build on past ideas
        or start fresh. Give it a try and see what new ideas you come up with.
      </div>
      <div className="flex justify-end">
        <CreateNewBrainstormSession
          projectId={projectId}
          finishedSessions={finishedSessions}
          org_tier={settings?.org_tier}
          hasEditorAccess={hasEditorAccess}
        />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Session Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Created</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {brainstormSessions?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                There are no sessions.
              </TableCell>
            </TableRow>
          )}
          {brainstormSessions?.map((session) => (
            <SessionItem
              key={session.session_id}
              {...session}
              projectId={projectId}
              finishedSessions={finishedSessions}
              hasEditorAccess={hasEditorAccess}
            />
          ))}
        </TableBody>
      </Table>

      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
              aria-disabled={page === 1}
              onClick={decreasePagination}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink className="cursor-default hover:bg-inherit">
              {page}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
              aria-disabled={isLastPage}
              onClick={increasePagination}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </>
  );
}
