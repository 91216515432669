import axiosInstance from "api/axiosInstance";

export const get_profile = async () => {
  const response = await axiosInstance({
    url: "/user/profile/",
    method: "GET"
  });
  return response.data;
}

export const update_user_name = async ({ user_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/user_name",
    method: "POST",
    data: { user_name: user_name }
  });
  return response.data;
}

export const update_research_name = async ({ research_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/research_name",
    method: "POST",
    data: { research_name: research_name }
  });
  return response.data;
}

export const add_user_interests = async ({ interest }) => {
  const response = await axiosInstance({
    url: "/user/profile/interests",
    method: "POST",
    data: { interest: interest }
  });
  return response.data;
}

export const delete_user_interests = async ({ interest }) => {
  const response = await axiosInstance({
    url: "/user/profile/interests",
    method: "DELETE",
    data: { interest: interest }
  });
  return response.data;
}

export const add_eligible_applicant_type = async ({id}) => {
  const response = await axiosInstance({
    url: "/user/profile/grants_applicant_types",
    method: "POST",
    data: { id: id }
  });
  return response.data;
}

export const delete_eligible_applicant_type = async ({id}) => {
  const response = await axiosInstance({
    url: "/user/profile/grants_applicant_types",
    method: "DELETE",
    data: { id: id }
  });
  return response.data;
}

export const update_company_name = async ({ company_name }) => {
  const response = await axiosInstance({
    url: "/user/profile/company_name",
    method: "POST",
    data: { company_name: company_name }
  });
  return response.data;
}

export const update_department = async ({ department }) => {
  const response = await axiosInstance({
    url: "/user/profile/department",
    method: "POST",
    data: { department: department }
  });
  return response.data;
}

export const update_job_title = async ({ job_title }) => {
  const response = await axiosInstance({
    url: "/user/profile/job_title",
    method: "POST",
    data: { job_title: job_title }
  });
  return response.data;
}

export const update_google_scholar = async ({ scholar_link }) => {
  const response = await axiosInstance({
    url: "/user/profile/google_scholar",
    method: "POST",
    data: { scholar_link: scholar_link }
  });
  return response.data;
}
export const update_semantic_scholar = async ({ semantic_scholar_link }) => {
  const response = await axiosInstance({
    url: "/user/profile/semantic_scholar",
    method: "POST",
    data: { semantic_scholar_link: semantic_scholar_link }
  });
  return response.data;
}