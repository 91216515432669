import { useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import useSWRMutation from "swr/mutation";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { add_project_roles } from "api";
import EditRole from "./EditRole";
import { LoadingSpinner } from "components";
import { mutate } from "swr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import clsx from "clsx";

const createOption = (label) => ({
  label,
  value: label,
});

export default function ShareProject({
  project_name,
  projectId,
  hasEditorAccess,
  users,
}) {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [roleAccess, setRoleAccess] = useState("viewer");

  const {
    isMutating: isAddingUsers,
    error: isAddingUsersError,
    trigger: addUsers,
  } = useSWRMutation(
    `add_users`,
    () =>
      add_project_roles({
        project_id: projectId,
        new_emails: value.map((v) => v.value),
        access: roleAccess,
      }),
    {
      onSuccess: () => {
        mutate(`get_project/${projectId}`);
        setInputValue("");
        setValue([]);
      },
    }
  );

  const handleKeyDown = async (e) => {
    let trimmedInputValue = inputValue.trim();
    if (!trimmedInputValue) return;
    if (
      e.key === "Enter" ||
      e.key === "Tab" ||
      e.key === "," ||
      e.key === " "
    ) {
      if (value.find((v) => v.value === trimmedInputValue)) {
        return;
      }

      setValue((p) => [...p, createOption(trimmedInputValue)]);
      setInputValue("");
      e.preventDefault();
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleDeleteUser = async (newValue, context) => {
    setValue(newValue);
  };

  return (
    <Dialog>
      <DialogTrigger className="flex items-center gap-x-2 btn-fuchsia text-base">
        Share <FaShareAlt />
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Share "{project_name}"</DialogTitle>
          <DialogDescription>
            Manage your project's share settings.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-4">
          {hasEditorAccess && (
            <>
              <div className="flex w-full gap-x-2">
                <CreatableSelect
                  classNames={{
                    container: () => clsx("w-full"),
                    control: ({ isFocused }) =>
                      clsx(
                        "border-1 rounded-md hover:border-fuchsia focus:border-fuchsia overflow-y-scroll ",
                        isFocused ? "border-fuchsia max-h-full" : "max-h-10"
                      ),
                  }}
                  styles={{ container: () => ({}), control: () => ({}) }}
                  components={{
                    DropdownIndicator: null,
                    ClearIndicator: null,
                  }}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleDeleteUser}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Add people (using their email)"
                  value={value}
                />

                <Select
                  value={roleAccess}
                  onValueChange={(v) => setRoleAccess(v)}
                  className="max-w-fit hover:border-fuchsia"
                >
                  <SelectTrigger className="max-w-fit hover:border-fuchsia data-[state=open]:border-fuchsia">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      { label: "Viewer", value: "viewer" },
                      { label: "Editor", value: "editor" },
                    ].map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {isAddingUsers ? (
                <button disabled={true} className="btn-fuchsia">
                  <LoadingSpinner /> Adding
                </button>
              ) : (
                <div>
                  <button
                    disabled={value.length === 0}
                    className="btn-fuchsia"
                    onClick={addUsers}
                  >
                    Share
                  </button>
                </div>
              )}

              {isAddingUsersError && (
                <span className="text-red-500">
                  There was an issue adding users to the project. Please try
                  again
                </span>
              )}
            </>
          )}
          <div className="flex flex-col border-y-2 pt-2 overflow-y-scroll max-h-52">
            {users.length > 0 ? (
              users.map((user) => (
                <EditRole
                  hasEditorAccess={hasEditorAccess}
                  key={user.user_id}
                  projectId={projectId}
                  {...user}
                />
              ))
            ) : (
              <span>This project is shared with no other users.</span>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
