export default function Feature({ icon, title, description, link }) {
  return (
    <div className="border-1 rounded-md p-2 flex flex-col gap-2">
      <div className="flex text-xl items-center gap-x-2">
        {icon} {title}
      </div>
      {description}
      {link}
    </div>
  );
}
