import axiosInstance from "./axiosInstance";

export const finalize_google_integration = async ({ token, state }) => {
  const response = await axiosInstance({
    url: "/user/oauth/google/callback",
    method: "POST",
    data: { token: token, state: state },
  });

  return response.data;
};
