import { get_google_access_token } from "api";
import { get_settings } from "api/settings";
import useDrivePicker from "react-google-drive-picker";
import useSWR from "swr";

export default function GoogleDrivePicker() {
  const {
    data: settings,
    isLoading: isSettingsLoading,
    error: settingsError,
  } = useSWR("get_settings", get_settings);
  const {
    data: accessToken,
    isLoading: isGettingAccessToken,
    error: accessTokenError,
  } = useSWR(
    settings?.google_drive_integration ? "google_access_token" : null,
    get_google_access_token
  );

  const [openPicker, authResponse] = useDrivePicker();
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "549549515103-p81986e9v4vpapgf749o8bb1ba7b8ejc.apps.googleusercontent.com",
      developerKey: "AIzaSyCQ8J_-VDb4VlrWnJc41kJahIQXyVgpOAc",
      viewId: "DOCS",
      token: accessToken.access_token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      appId: "549549515103",
      scope: "https://www.googleapis.com/auth/drive.file",
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          const file_id = data.docs[0].id;
          console.log(file_id);
          fetch(`https://www.googleapis.com/drive/v3/files/${file_id}`, {
            headers: {
              Authorization: `Bearer ${accessToken.access_token}`,
            },
          });
        }
      },
    });
  };

  return (
    <div>
      <button onClick={() => handleOpenPicker()}>Open Picker</button>
    </div>
  );
}
