export default function Title({ className, value }) {
  return (
    <div className="relative w-fit mb-2">
      <div className={"relative font-semibold pl-2 pr-4 z-[1] " + className}>
        {value}
      </div>
      <div className="absolute w-full rounded-3xl bg-pastel-fuchsia top-1/3 bottom-0" />
    </div>
  );
}
