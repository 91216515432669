import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";

export default function UploadPaperViaLink({ projectId, trigger }) {
  const [link, setLink] = useState("");
  const [domain, setDomain] = useState("other");

  useEffect(() => {
    try {
      const { hostname } = new URL(link);
      switch (hostname) {
        case "www.arxiv.org":
        case "arxiv.org":
          setDomain("arxiv");
          break;
        case "www.biorxiv.org":
        case "biorxiv.org":
          setDomain("biorxiv");
          break;
        case "www.pubmed.ncbi.nlm.nih.gov":
        case "pubmed.ncbi.nlm.nih.gov":
          setDomain("pubmed");
          break;
        case "www.medrxiv.org":
        case "medrxiv.org":
          setDomain("medrxiv");
          break;
        default:
          setDomain("other");
          break;
      }
    } catch (error) {
      setDomain("other");
    }
  }, [link]);

  const onUpload = () => {
    trigger({ project_id: projectId, link: link, upload_type: domain });
  };

  return (
    <>
      <div>
        <Tooltip>
          <TooltipTrigger showIcon={true}>
            <span className="font-semibold">Insert Link to PDF</span>
          </TooltipTrigger>
          <TooltipContent>
            Drop in a link to any relevant paper below. We currently only accept
            links to publicly available pdf files that are under 10MB in size.
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="flex gap-x-2">
        <Input value={link} onChange={(e) => setLink(e.target.value)} />
        <Select
          className="border-2 rounded-md"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="arxiv">Arxiv</SelectItem>
            <SelectItem value="biorxiv">Biorxiv</SelectItem>
            <SelectItem value="pubmed">Pubmed</SelectItem>
            <SelectItem value="medrxiv">medRxiv</SelectItem>
            <SelectItem value="other">Other</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="w-full">
        <button
          disabled={link.length === 0}
          className="btn-fuchsia"
          onClick={onUpload}
        >
          Upload
        </button>
      </div>
    </>
  );
}
