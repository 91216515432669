import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { add_brainstorm_ideas } from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function ManualAddIdea({ projectId }) {
  const [idea, setIdea] = useState("");
  const [description, setDescription] = useState("");

  const {
    isMutating,
    error: isUpdateError,
    trigger: addBrainstormIdea,
  } = useSWRMutation(
    `add_brainstorm_ideas`,
    () =>
      add_brainstorm_ideas({
        project_id: projectId,
        brainstorm_ideas: [{ item: idea, description: description }],
        ai_generated: false,
      }),
    {
      onSuccess: () => {
        setIdea("");
        setDescription("");
        mutate(`/project/idea/brainstorm/${projectId}`);
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger className="text-fuchsia hover:underline">
        Add your own idea
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add a brainstorming idea</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-2">
          <div>
            <span className="font-semibold">Idea</span>
            <Input
              value={idea}
              onChange={(e) => setIdea(e.target.value)}
              title="idea"
            />
          </div>
          <div>
            <span className="font-semibold">Description</span>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              title={"Description"}
            />
          </div>
          <div className="flex gap-4 w-full justify-end">
            {isMutating ? (
              <button
                disabled={true}
                className="btn-fuchsia flex items-center gap-x-2"
              >
                Updating <LoadingSpinner />
              </button>
            ) : (
              <button
                disabled={idea.length === 0 || description.length === 0}
                className="btn-fuchsia"
                onClick={addBrainstormIdea}
              >
                Add Idea
              </button>
            )}
          </div>
          {isUpdateError && (
            <span className="text-red-500">
              There was an error adding your idea. Please try again.
            </span>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
