import { HiOutlineUserCircle } from "react-icons/hi2";

export default function UserChatMessage({ text }) {
  return (
    <div className="w-full self-end flex justify-end items-end gap-x-2 pb-8">
      <div className="bg-pastel-blue flex flex-col px-4 py-1 rounded-2xl rounded-ee-none text-sm max-w-lg">
        {text}
      </div>
      <div className="rounded-full bg-light-grey border-2 h-fit w-fit translate-y-1/2">
        <HiOutlineUserCircle className="w-8 h-8" />
      </div>
    </div>
  );
}
