import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { add_required_file } from "api";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const files = [
  "Summary",
  "Description",
  "References",
  "Budget",
  "Budget Justification",
  "Data Management",
  "Facilities, Equipment, and Other Resources",
  "Biographical Sketch(es)",
  "Current and Pending Support",
  "Collaborators & Other Affiliations Information",
  "Postdoctoral Researcher Mentoring Plan",
  "Graduate Student Mentoring Plan",
  "Project Personnel and Partner Organizations",
  "Cloud Computing Resources (if applicable)",
  "Collaboration Plans for Medium projects (if applicable)",
  "Broadening Participation in Computing (BPC) Plan (if applicable)",
  "Letters of Collaboration (if applicable)",
];

export default function NSF_Tab({ projectId, requiredFiles }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleToggleFile = (name) => {
    if (selectedFiles.includes(name)) {
      setSelectedFiles((f) => f.filter((v) => v !== name));
    } else {
      setSelectedFiles((f) => [...f, name]);
    }
  };

  const { trigger: addFiles } = useSWRMutation(
    `add_required_file`,
    async () => {
      await Promise.all(
        selectedFiles.map((name) =>
          add_required_file({
            project_id: projectId,
            name: name,
          })
        )
      );
    },
    {
      onSuccess: () => {
        mutate(`get_required_files/${projectId}`);
        setSelectedFiles([]);
      },
    }
  );

  return (
    <>
      <ScrollArea className="pr-2">
        <div className="flex flex-col gap-y-4 max-h-96 p-2">
          {files
            .filter(
              (name) =>
                !requiredFiles.some(({ file_info }) => file_info === name)
            )
            .map((name) => (
              <div
                key={name}
                className="rounded-md p-1 gap-x-1 flex items-center bg-pastel-blue hover:ring-2 hover:cursor-pointer ring-fuchsia text-left w-full  "
                onClick={() => handleToggleFile(name)}
              >
                <Checkbox
                  className="bg-white"
                  checked={selectedFiles.includes(name)}
                />
                <div>
                  <div className="font-semibold">{name}</div>
                </div>
              </div>
            ))}
        </div>
      </ScrollArea>
      <div className="flex justify-end">
        <button
          className="btn-primary"
          onClick={addFiles}
          disabled={selectedFiles.length === 0}
        >
          Add ({selectedFiles.length})
        </button>
      </div>
    </>
  );
}
