import { upload_paper_via_file, upload_paper_via_link } from "api";
import { ErrorPage, LoadingPage, LoadingSpinner } from "components";
import useSWRMutation from "swr/mutation";
import UploadPaperViaLink from "./UploadPaperViaLink";
import UploadPaperViaFile from "./UploadPaperViaFile";
import { useState } from "react";
import { mutate } from "swr";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function UploadPaperTab({ projectId }) {
  const [isGoogleStorageUploadError, setIsUploadError] = useState(false);

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: linkData,
    isMutating: isLinkUploadMutating,
    error: isLinkUploadError,
    trigger: triggerLink,
    reset: resetLink,
  } = useSWRMutation(
    `upload_paper_via_link/${projectId}`,
    (key, { arg }) => {
      return upload_paper_via_link(arg);
    },
    {
      onSuccess: () => mutate(`get_papers?${projectId}`),
    }
  );
  const {
    data: fileData,
    isMutating: isFileUploadMutating,
    error: isFileUploadError,
    trigger: triggerFile,
    reset: resetFile,
  } = useSWRMutation(
    `upload_paper_via_file/${projectId}`,
    (key, { arg }) => {
      return upload_paper_via_file(arg);
    },
    {
      onSuccess: () => {
        mutate(`get_papers?${projectId}`, undefined, { revalidate: true });
      },
    }
  );

  const clearUploadData = () => {
    setIsUploadError(false);
    resetLink();
    resetFile();
  };

  if (isEditorLoading) return <LoadingPage />;
  if (editorError) return <ErrorPage />;
  if (!hasEditorAccess)
    return (
      <p>
        Sorry you do not have access to upload papers. Please contact the owner
        of the project to gain access.
      </p>
    );

  if (isLinkUploadMutating || isFileUploadMutating)
    return (
      <div className="flex flex-col items-center justify-center gap-y-4">
        <LoadingSpinner className="text-fuchsia h-40 w-40" />
        <span className="font-semibold">Uploading Paper</span>
      </div>
    );

  return (
    <div className="flex flex-col gap-y-4 mt-4 px-2 grow h-full">
      {(isLinkUploadError ||
        isFileUploadError ||
        isGoogleStorageUploadError) && (
        <span className="text-red-500">
          There was an issue uploading via link. Please try again.
        </span>
      )}

      {linkData || fileData ? (
        <div className="flex flex-col justify-center items-center gap-y-2 grow h-full">
          <p className="text-2xl font-semibold">Paper has been uploaded!</p>
          <div>
            <button
              className="btn-fuchsia rounded-2xl px-3"
              onClick={clearUploadData}
            >
              Upload another paper
            </button>
          </div>
        </div>
      ) : (
        <>
          <UploadPaperViaLink projectId={projectId} trigger={triggerLink} />
          <div className="flex w-full items-center gap-x-4">
            <div className="w-full border-1 h-0"></div>
            <span className="font-semibold">OR</span>
            <div className="w-full border-1 h-0"></div>
          </div>
          <UploadPaperViaFile projectId={projectId} trigger={triggerFile} />
        </>
      )}
    </div>
  );
}
