import React from "react";

export default function Error() {

  return (
    <div className="flex justify-center items-center h-screen w-screen">
    <div className="flex justify-center items-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 font-bold bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          Something went wrong, please try reloading the page.

        </div>
      </div>
    </div>
    </div>
  );
}
