import { useEffect, useId, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { format, isValid, parse } from "date-fns";
import { formatInTimeZone, parseInTimeZone } from "date-fns-tz";
import { Input } from "@/components/ui/input";
import {} from "@radix-ui/react-popover";

/** Render an input field bound to a DayPicker calendar. */
/**
 *
 * @param {*} value in the format "MM/dd/yyyy"
 * @param {*} onChange function to update value
 * @returns
 */
export function DatePicker({
  value,
  onChange,
  containerRef,
  hasEditorAccess = true,
}) {
  // Helps control when the calendar opens/closes
  const inputId = useId();

  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(value || new Date());

  // Hold the selected date in state
  const [selectedDate, setSelectedDate] = useState(value || undefined);

  // Hold the input value in state
  const [inputValue, setInputValue] = useState(
    value ? formatInTimeZone(new Date(value), "UTC", "MM/dd/yyyy") : ""
  );

  const handleCalendarSelect = (date) => {
    if (!date) {
      setInputValue("");
      setSelectedDate(undefined);
    } else {
      setSelectedDate(date);
      setMonth(date);
      setInputValue(format(date, "MM/dd/yyyy"));
      onChange?.(date);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // keep the input value in sync
    const parsedDate = parse(e.target.value, "MM/dd/yyyy", new Date());

    if (isValid(parsedDate)) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
    } else {
      setSelectedDate(undefined);
    }
  };

  const handleInputBlur = (e) => {
    if (isValid(selectedDate)) {
      onChange?.(selectedDate);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Input
          id={inputId}
          type="text"
          disabled={!hasEditorAccess}
          value={inputValue}
          placeholder="mm/dd/yyyy"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
        />
      </PopoverTrigger>
      <PopoverContent
        container={containerRef?.current}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Calendar
          mode="single"
          month={month}
          onMonthChange={setMonth}
          selected={selectedDate}
          onSelect={handleCalendarSelect}
        />
      </PopoverContent>
    </Popover>
  );
}
