import React from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { LoadingSpinner } from "components";
import { verifyUser } from "api/auth";
import useSWR from "swr";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();
  const token = searchParams.get("token");

  const { isLoading, error: isError } = useSWR("/verify_user",
    () => verifyUser({ verification_code: token }),
    {
      onSuccess: () => {
        setTimeout(() => { navigate("/login", { state: { isFirstVerified: true } }) }, 2000);
      }
    }
  );

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          <span className="font-bold">Confirming Email</span>
          <div className="flex justify-center">
            {isLoading ? <LoadingSpinner className="text-fuchsia h-20 w-20" /> :
              (
                isError ? <p>There was an unexpected error. Please try again.</p>
                  : <p>Successfully verified, redirecting to login in a few moments...</p>
              )

            }
          </div>
        </div>
      </div>
    </div>
  );
}
