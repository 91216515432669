import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { PiggyBankIcon, User2Icon, WorkflowIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export default function SettingsSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const isSettingsRoute = pathname.startsWith("/settings");
  if (!isSettingsRoute) return null;
  return (
    <Sidebar collapsible="none" className="hidden flex-1 pt-4 md:flex">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/settings/profile`}
                >
                  <Link to={`/settings/profile`}>
                    <User2Icon /> Profile
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/settings/billing`}
                >
                  <Link to={`/settings/billing`}>
                    <PiggyBankIcon />
                    Billing
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/settings/integrations`}
                >
                  <Link to={`/settings/integrations`}>
                    <WorkflowIcon />
                    Integrations
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
