import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useFeedbackStore = create(
  persist(
    (set) => ({
      projectFeedback: new Map(),
      setProjectFeedback: (projectId, b) =>
        set((state) => ({
          projectFeedback: new Map(state.projectFeedback).set(projectId, b),
        })),
    }),
    {
      name: "feedback-store",
      partialize: (state) => ({ projectFeedback: state.projectFeedback }),
      storage: {
        getItem: (name) => {
          const str = localStorage.getItem(name);
          return {
            state: {
              ...JSON.parse(str).state,
              projectFeedback: new Map(JSON.parse(str).state.projectFeedback),
            },
          };
        },
        setItem: (name, newValue) => {
          const str = JSON.stringify({
            state: {
              ...newValue.state,
              projectFeedback: Array.from(
                newValue.state.projectFeedback.entries()
              ),
            },
          });
          localStorage.setItem(name, str);
        },
        removeItem: (name) => localStorage.removeItem(name),
      },
    }
  )
);

export const useFeedback = (projectId) => {
  const { projectFeedback, setProjectFeedback } = useFeedbackStore();
  const setHasGivenFeedback = (b) => {
    setProjectFeedback(projectId, b);
  };

  return {
    hasGivenFeedback: projectFeedback.get(projectId),
    setHasGivenFeedback: setHasGivenFeedback,
  };
};
