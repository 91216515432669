import { Sidebar, useSidebar } from "@/components/ui/sidebar";
import ProjectSidebar from "./sidebars/ProjectSidebar";
import IconSidebar from "./IconSidebar";
import SettingsSidebar from "./sidebars/SettingsSidebar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function AppSidebar() {
  const { setOpen } = useSidebar();
  const location = useLocation();
  const { pathname } = location;
  const isProjectRoute = pathname.startsWith("/project");
  const isSettingsRoute = pathname.startsWith("/settings");

  useEffect(() => {
    if (!isProjectRoute && !isSettingsRoute) setOpen(false);
    else setOpen(true);
  }, [isProjectRoute, isSettingsRoute]);

  return (
    <>
      <ProjectSidebar />
      <SettingsSidebar />
    </>
  );
}
