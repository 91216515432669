import useSWRMutation from "swr/mutation";
import useDrivePicker from "react-google-drive-picker";
import { useState } from "react";
import { FaGoogleDrive } from "react-icons/fa6";
import { add_new_review, add_new_review_via_google } from "api/review_space";
import useSWR, { mutate } from "swr";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ErrorPage, LoadingPage } from "components";
import { get_settings } from "api/settings";
import { get_google_access_token } from "api";

export default function UploadFromDrive({ projectId, prompt_upgrade }) {
  const {
    data: settings,
    isLoading: isSettingsLoading,
    error: settingsError,
  } = useSWR("get_settings", get_settings);
  const {
    data: accessToken,
    isLoading: isGettingAccessToken,
    error: accessTokenError,
  } = useSWR(
    settings?.google_drive_integration ? "google_access_token" : null,
    get_google_access_token
  );

  const [openPicker, authResponse] = useDrivePicker();

  const {
    data: fileData,
    isMutating: isFileUploading,
    error: isFileUploadError,
    trigger: uploadFile,
    reset: resetFile,
  } = useSWRMutation(
    `add_new_review_via_google/${projectId}`,
    (_, { arg }) =>
      add_new_review_via_google({
        project_id: projectId,
        google_file_object: arg,
      }),
    {
      onSuccess: (d) => {
        console.log(d);
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  if (isSettingsLoading || isGettingAccessToken) return <LoadingPage />;
  if (settingsError || accessTokenError) return <ErrorPage />;

  const handleOpenPicker = () => {
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_PICKER_API_KEY,
      viewId: "DOCS",
      token: accessToken.access_token, // pass oauth token in case you already have one
      appId: "549549515103",
      customScopes: ["https://www.googleapis.com/auth/drive.file"],
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else {
          console.log(data.docs);
        }

        if (data.action === "picked") {
          const google_file_object = {
            id: data.docs[0].id,
            name: data.docs[0].name,
            mimeType: data.docs[0].mimeType,
          };
          fetch(
            `https://www.googleapis.com/drive/v3/files/${data.docs[0].id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken.access_token}`,
              },
            }
          ).then(() => {
            uploadFile(google_file_object);
          });
        }
      },
    });
  };

  if (prompt_upgrade)
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-sm"
          disabled={prompt_upgrade}
        >
          <FaGoogleDrive />
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );

  return (
    <button className="btn-fuchsia" onClick={handleOpenPicker}>
      <FaGoogleDrive />
    </button>
  );
}
