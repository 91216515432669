import { ErrorPage, LoadingPage, Title } from "components";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from "@/components/ui/table";
import CreateNewTemplate from "./CreateNewTemplate";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { PageHelp } from "@/components/styled/pageHelp";
import Template from "./Template";
import { list_templates } from "api/write_space";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function ListTemplates() {
  const { projectId } = useParams();

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: templates,
    isLoading: isGettingTemplates,
    error: templatesError,
  } = useSWR(`/write/template/list?${projectId}`, () =>
    list_templates({ project_id: projectId })
  );

  if (isEditorLoading || isGettingTemplates) return <LoadingPage />;
  if (editorError || templatesError) return <ErrorPage />;

  return (
    <div className="bg-white grow rounded-lg h-full">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Proposal Outlines" />
        <PageHelp
          title={"Write Space"}
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                Use the Writing Space to draft and refine the messaging and
                content of your proposals.{" "}
              </div>
              <div>
                <h2 className="font-semibold">Proposal Outlines</h2>
                Build a structured outline for your proposals, and leverage AI
                assistance to craft the precise messaging you want in each
                section.
              </div>
            </div>
          }
        />
      </div>

      <div className="flex justify-end">
        {hasEditorAccess && <CreateNewTemplate projectId={projectId} />}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Template Name</TableHead>
            <TableHead>Created</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {templates.map((template) => (
            <Template
              projectId={projectId}
              key={template.template_id}
              {...template}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
