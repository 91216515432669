import { MdOutlineExitToApp } from "react-icons/md";
import SortableList from "./SortableList";
import ChoosePreset from "./ChoosePreset";

export default function Editor({
  headers,
  templateId,
  projectId,
  setIsEditorView,
  headerLabels,
  isInitialOutlineGeneration,
}) {
  return (
    <>
      <div>
        <button
          onClick={() => setIsEditorView(false)}
          className="text-sm flex items-center gap-x-2 hover:text-fuchsia"
        >
          <MdOutlineExitToApp className="-scale-x-100" />
          Exit Edit Mode
        </button>
      </div>
      {headers.length ? (
        <SortableList
          headers={headers}
          templateId={templateId}
          projectId={projectId}
          headerLabels={headerLabels}
          isInitialOutlineGeneration={isInitialOutlineGeneration}
        />
      ) : (
        <ChoosePreset templateId={templateId} projectId={projectId} />
      )}
    </>
  );
}
