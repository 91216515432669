import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { create_followup_session } from "api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechnical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Computer Engineering", value: "computer engineering" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function CreateFollowupSession({ sessionId, projectId }) {
  const navigate = useNavigate();
  const {
    isMutating: isCreatingFollowupSession,
    error: createFollowupSessionError,
    trigger: createFollowupSession,
  } = useSWRMutation(
    `create_followup_session`,
    () =>
      create_followup_session({
        project_id: projectId,
        session_id: sessionId,
        name: sessionTitle,
        agent_1_background: agent1Background,
        agent_2_background: agent2Background,
      }),
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate(`get_brainstorm_sessions/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent2Background, setAgent2Background] = useState("");

  return (
    <div className="flex justify-center">
      <Dialog>
        <DialogTrigger>
          <span className="btn-fuchsia hover:opacity-80 hover:cursor-pointer rounded-2xl">
            Start Followup Session
          </span>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Create Followup Session
            </DialogTitle>
            <DialogDescription>Create a followup from where you left off in this session.</DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-y-4">
            <Input
              placeholder="Session Title"
              value={sessionTitle}
              onChange={(e) => setSessionTitle(e.target.value)}
            />

            <Select
              value={agent1Background}
              onValueChange={(v) => setAgent1Background(v)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Researcher 1 Expertise" />
              </SelectTrigger>
              <SelectContent>
                {agent_types.map(({ label, value }) => (
                  <SelectItem value={value} key={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select
              value={agent2Background}
              onValueChange={(v) => setAgent2Background(v)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Researcher 2 Expertise" />
              </SelectTrigger>
              <SelectContent>
                {agent_types.map(({ label, value }) => (
                  <SelectItem value={value} key={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <div>
              <button
                onClick={createFollowupSession}
                disabled={!agent1Background || !agent2Background}
                className="btn-fuchsia"
              >
                Start Session
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
