import React from 'react';

const LoadingPage = (props) => {
    return (
        <div className="bg-white flex flex-col justify-center items-center grow rounded-lg p-4 gap-y-4 h-full">
            <svg className='text-fuchsia' xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"><animateTransform attributeName="transform" dur="1.4s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform></path></svg>
            <p className="font-bold">Loading...</p>
        </div>);
}

export default LoadingPage;
