import {
  connect_google_drive,
  disconnect_google_drive,
  get_settings,
} from "api/settings";
import { ErrorPage, LoadingPage, Title } from "components";
import useSWR, { mutate } from "swr";
import GoogleDrive from "assets/integrations/google-drive.png";
import { CheckIcon } from "lucide-react";
import useSWRMutation from "swr/mutation";

export default function Integrations() {
  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const { trigger: connectGoogleDrive } = useSWRMutation(
    "connect_google_drive",
    connect_google_drive,
    {
      onSuccess: ({ url }) => (window.location.href = url),
    }
  );

  const { trigger: disconnectGoogleDrive } = useSWRMutation(
    "disconnect_google_drive",
    disconnect_google_drive,
    { onSuccess: () => mutate("get_settings") }
  );

  if (isGettingSettings) return <LoadingPage />;
  if (settingsError) return <ErrorPage />;

  return (
    <div>
      <Title value={"Integrations"} className={"text-4xl"} />
      {settings?.prompt_upgrade && (
        <p>To use integrations, please upgrade your plan.</p>
      )}
      <div className="border-1 rounded-md p-4 max-w-lg flex flex-col gap-4 mt-4">
        <div className="text-xl font-semibold flex items-center gap-x-2">
          <img alt="Google Drive Icon" src={GoogleDrive} className="h-8 w-8" />
          Google Drive
        </div>
        <div>
          Allow us to import your selected files from Google Drive to process
          and analyze.
        </div>
        {/* <div className="font-semibold flex justify-end">Coming Soon!</div> */}
        <div className="flex justify-end">
          {settings.google_drive_integration ? (
            <button className="btn-primary" onClick={disconnectGoogleDrive}>
              <CheckIcon /> Connected
            </button>
          ) : (
            <button
              className="btn-secondary"
              onClick={connectGoogleDrive}
              disabled={settings?.org_tier === "starter"}
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
