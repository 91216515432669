import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { get_chat_uuid } from "api";
import { red_team_chat_send_message } from "api/review_space";
import { LoadingSpinner } from "components";
import { MessageCircleQuestionIcon, SendIcon } from "lucide-react";
import { useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";

export default function CommentChat({ fileId, reviewer, sectionID, title }) {
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState("");

  const { data: chatUUID, isLoading: isGettingChatUUID } = useSWR(
    open ? `reviewer_feedback_chat/${reviewer}/${sectionID}` : null,
    get_chat_uuid
  );

  const { trigger: sendMessage, isMutating: isSendingMessage } = useSWRMutation(
    "send_message",
    () =>
      red_team_chat_send_message({
        uuid: chatUUID?.uuid,
        message: message,
        file_id: fileId,
        reviewer: reviewer.replace(" ", "_").toLowerCase(),
        section_id: sectionID,
      }),
    {
      onSuccess: (response) => {
        setHistory((p) => [
          ...p,
          { sender: "User", message: message },
          { sender: reviewer, message: response.text },
        ]);
        setMessage("");
      },
    }
  );

  useEffect(() => {
    if (history.length === 0) return;
    const index = history.length - 1;
    document
      .getElementById(index)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }, [history]);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="absolute right-0 top-0 rounded-full text-black hover:text-fuchsia"
      >
        <MessageCircleQuestionIcon className="h-7 w-7" />
      </button>
      <DropdownMenu open={open} onOpenChange={(open) => setOpen(open)}>
        <DropdownMenuTrigger className="fixed bottom-4 right-4"></DropdownMenuTrigger>
        <DropdownMenuContent
          style={{ width: "50vw" }}
          className="flex flex-col min-w-80 h-96 text-sm"
        >
          <div className=" w-full gap-x-2 items-center">
            <span className="capitalize whitespace-nowrap">{reviewer}</span>
            <span className="flex grow w-full">{title}</span>
          </div>
          <ScrollArea className="flex flex-col grow py-2 pr-2 gap-y-2">
            {history.map(({ sender, message }, i) =>
              sender === "User" ? (
                <div
                  key={i}
                  className="w-11/12 bg-blue text-white rounded-lg max-w-fit justify-self-end p-2 my-1"
                >
                  {message}
                </div>
              ) : (
                <div
                  id={i}
                  key={i}
                  className="w-11/12 bg-pastel-blue rounded-lg max-w-fit p-2 my-1"
                >
                  <div className="text-blue font-semibold capitalize">
                    {sender.replace("_", " ")}
                  </div>
                  {message}
                </div>
              )
            )}
            {isSendingMessage && (
              <div className="w-11/12 bg-pastel-blue rounded-lg max-w-fit p-2 my-1">
                <div className="text-blue font-semibold capitalize flex items-center gap-x-2">
                  Loading <LoadingSpinner />
                </div>
              </div>
            )}
          </ScrollArea>
          <div className="flex gap-x-2">
            <Input
              placeholder="Ask a question"
              disabled={isSendingMessage}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <button
              disabled={
                isGettingChatUUID || isSendingMessage || message.length === 0
              }
              className="btn-fuchsia"
              onClick={sendMessage}
            >
              Send {isSendingMessage ? <LoadingSpinner /> : <SendIcon />}
            </button>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
