import { delete_file_review, get_file_review } from "api/review_space";
import { ErrorPage, LoadingPage } from "components";
import { Link, useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import FileSummary from "./FileSummary";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FaArrowLeft } from "react-icons/fa";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MdDelete } from "react-icons/md";
import { DialogClose } from "@/components/ui/dialog";
import useSWRMutation from "swr/mutation";
import { PageHelp } from "@/components/styled/pageHelp";
import { useIsEditor } from "@/hooks/use-is-editor";
import ReviewerFeedback from "./ReviewerFeedback";
import Comments from "./Comments";

export default function FileReview() {
  const { projectId, fileId } = useParams();
  const navigate = useNavigate();

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: fileReview,
    isLoading: isGettingFileReview,
    error: getFileReviewError,
  } = useSWR(`get_file_review/${fileId}`, () =>
    get_file_review({ file_id: fileId })
  );

  const {
    isLoading: isDeletingReview,
    error: deleteReviewError,
    trigger: deleteReview,
  } = useSWRMutation(
    `delete_review`,
    () => delete_file_review({ project_id: projectId, file_id: fileId }),
    {
      onSuccess: () => {
        mutate(`list_reviews/${projectId}`);
        navigate(`/project/${projectId}/review-space`);
      },
    }
  );

  if (isEditorLoading || isGettingFileReview) return <LoadingPage />;
  if (editorError || getFileReviewError) return <ErrorPage />;

  return (
    <div className="flex flex-col grow h-full">
      <div className="flex justify-between items-center">
        <div className="flex gap-x-2 items-center text-2xl">
          <Link
            to={`/project/${projectId}/review-space`}
            className="hover:text-fuchsia"
          >
            <FaArrowLeft />
          </Link>
          {fileReview.file_name}
        </div>
        <div className="flex gap-x-2">
          {hasEditorAccess && (
            <>
              <Dialog>
                <DialogTrigger>
                  <MdDelete />
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Delete Proposal Review</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to permanently delete this proposal
                      review?
                    </DialogDescription>
                  </DialogHeader>
                  <div className="flex flex-col gap-y-8">
                    <div className="flex justify-between">
                      <DialogClose asChild>
                        <button className="text-zinc-600 hover:underline">
                          Cancel
                        </button>
                      </DialogClose>
                      <button
                        className="flex gap-x-1 items-center text-red-500 hover:underline"
                        onClick={deleteReview}
                        disabled={isDeletingReview}
                      >
                        <MdDelete /> Delete
                      </button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}

          <PageHelp
            title="Review Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  Red Team Reviews provide quick feedback on your proposals. In
                  just a few minutes, you'll receive a summary of key takeaways,
                  along with an overview of strengths, areas for improvement,
                  and specific feedback from Reviewers 1 and 3 (because we all
                  know Reviewer 2 never actually read the paper!). We currently
                  accept only PDF and DOCX files under 10MB.
                </div>
              </div>
            }
          />
        </div>
      </div>
      <Tabs defaultValue="overview" className="flex flex-col grow h-full">
        <div>
          <TabsList>
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="comments">Comments</TabsTrigger>
            <TabsTrigger value="summary">Summary</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="overview" className="h-full">
          <ReviewerFeedback fileId={fileId} outlines={fileReview.outlines} />
        </TabsContent>
        <TabsContent value="comments" className="h-full">
          <Comments fileId={fileId} />
        </TabsContent>
        <TabsContent value="summary">
          <FileSummary
            general_outline={fileReview.outlines.general}
            summary={fileReview.summary}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}
