import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { add_brainstorm_ideas } from "api";
import { LoadingSpinner } from "components";
import { LightbulbIcon } from "lucide-react";
import { useState } from "react";
import useSWRMutation from "swr/mutation";

export default function AddMessageToIdeas({ sender, message, projectId }) {
  const [item, setItem] = useState(sender);
  const [description, setDescription] = useState(message);

  const {
    data: hasSent,
    trigger: addIdea,
    isMutating: isAddingIdea,
  } = useSWRMutation("add_message_to_idea", () =>
    add_brainstorm_ideas({
      project_id: projectId,
      brainstorm_ideas: [{ item: item, description: description }],
    })
  );

  return (
    <Dialog>
      <Tooltip>
        <DialogTrigger asChild>
          <TooltipTrigger className="hover:text-fuchsia">
            <LightbulbIcon className="w-5 h-5" />
          </TooltipTrigger>
        </DialogTrigger>

        <TooltipContent>Add message to ideas</TooltipContent>
      </Tooltip>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add Message to Ideas</DialogTitle>
          <DialogDescription>Add this message to your ideas</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-4">
          <div>
            <span className="font-semibold">Idea</span>
            <Input value={item} onChange={(e) => setItem(e.target.value)} />
          </div>
          <div>
            <span className="font-semibold">Description</span>
            <Textarea
              className="min-h-40"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <DialogClose className="hover:underline">Close</DialogClose>
          {hasSent ? (
            <DialogClose className="hover:underline">Added!</DialogClose>
          ) : (
            <button
              className="btn btn-primary"
              onClick={addIdea}
              disabled={isAddingIdea}
            >
              Add
              {isAddingIdea && <LoadingSpinner />}
            </button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
