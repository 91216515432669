import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Sidebar,
  SidebarContent,
  SidebarGroupContent,
  SidebarGroup,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarGroupLabel,
  useSidebar,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarMenuSubButton,
} from "@/components/ui/sidebar";
import { ChevronDown, FileCheck2Icon, ListTodoIcon } from "lucide-react";
import { useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaRegFileLines, FaRegLightbulb } from "react-icons/fa6";
import { Link, useLocation, useParams } from "react-router-dom";

export default function ProjectSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const { projectId } = useParams();
  const isProjectRoute = pathname.startsWith("/project");
  const projectSpaces = [
    {
      title: "Ideation",
      icon: <FaRegLightbulb />,
      items: [
        { title: "Research Idea", url: `/project/${projectId}/ideation-space` },
        {
          title: "Background Material",
          url: `/project/${projectId}/ideation-space/background-material`,
        },
        // {
        //   title: "Brainstorm Ideas",
        //   url: `/project/${projectId}/ideation-space/brainstorming`,
        // },
        {
          title: "Brainstorm",
          url: `/project/${projectId}/ideation-space/brainstorm`,
        },
      ],
    },
    {
      title: "Grant",
      icon: <FaRegFileLines />,
      items: [
        {
          title: "Recommendations",
          url: `/project/${projectId}/grant-space/recommended-calls`,
        },
        {
          title: "Search",
          url: `/project/${projectId}/grant-space/search-calls`,
        },
        {
          title: "Bookmarks",
          url: `/project/${projectId}/grant-space/bookmarks`,
        },
      ],
    },
    {
      title: "Write",
      icon: <FaRegEdit />,
      items: [
        {
          title: "Proposal Outlines",
          url: `/project/${projectId}/write-space`,
        },
      ],
    },
    {
      title: "Review",
      icon: <FileCheck2Icon />,
      items: [
        {
          title: "Proposal Reviews",
          url: `/project/${projectId}/review-space`,
        },
      ],
    },
    {
      title: "Admin",
      icon: <ListTodoIcon />,
      items: [
        {
          title: "Selected Call",
          url: `/project/${projectId}/admin-space/selected-call`,
        },
        {
          title: "Action Items",
          url: `/project/${projectId}/admin-space/action-items`,
        },
        {
          title: "Document Checklist",
          url: `/project/${projectId}/admin-space/document-checklist`,
        },
      ],
    },
  ];

  if (!isProjectRoute) return null;

  return (
    <Sidebar collapsible="none" className="hidden flex-1 pt-4 md:flex">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={pathname === `/project/${projectId}`}
                >
                  <Link to={`/project/${projectId}`}>Project Dashboard</Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {projectSpaces.map((space) => (
          <Collapsible
            defaultOpen={true}
            className="group/collapsible"
            key={space.title}
          >
            <SidebarGroup>
              <SidebarGroupLabel asChild>
                <CollapsibleTrigger className="gap-x-2 hover:bg-offblack text-white pr-4">
                  {space.icon}
                  {space.title}
                  <ChevronDown className="ml-auto transition-transform -rotate-90 group-data-[state=open]/collapsible:rotate-0" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenuSub className="border-offblack border-l-2">
                    {space.items.map((item) => (
                      <SidebarMenuSubItem key={item.title}>
                        <SidebarMenuSubButton
                          asChild
                          isActive={pathname === item.url}
                        >
                          <Link to={item.url}>
                            <span>{item.title}</span>
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        ))}
      </SidebarContent>
    </Sidebar>
  );
}
