import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { add_brainstorm_ideas, generate_brainstorm_ideas } from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import Idea from "./Idea";
export default function GenerateBrainstormIdeas({
  projectId,
  isResearchIdeaFilledOut,
}) {
  const [promptToUpgrade, setPromptToUpgrade] = useState(false);
  const [isGenerateIdeasOpen, setIsGenerateIdeasOpen] = useState(false);
  const [generatedIdeas, setGeneratedIdeas] = useState([]);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const {
    isMutating: isGeneratedIdeasMutating,
    error: generatedIdeasError,
    trigger: generateNewIdeas,
  } = useSWRMutation(
    `/process/idea/brainstorm/${projectId}`,
    () => generate_brainstorm_ideas({ project_id: projectId }),
    {
      onSuccess: (d) => {
        if (d.hasOwnProperty("credits_used")) {
          mutate(
            `get_settings`,
            (d) => ({ ...d, credits_left: d.credits_left - 1 }),
            {
              revalidate: false,
            }
          );
        }
        setGeneratedIdeas(d.ideas);
      },
      onError: (err) => {
        setIsGenerateIdeasOpen(false);
        if (err.status === 422) {
          setPromptToUpgrade(true);
        }
      },
      throwOnError: false,
    }
  );

  const {
    isMutating: isAddingGeneratedIdeas,
    error: addGenerateIdeasError,
    trigger: addGeneratedIdeas,
  } = useSWRMutation(
    `update_brainstorm_ideas`,
    () =>
      add_brainstorm_ideas({
        project_id: projectId,
        brainstorm_ideas: selectedIdeas,
        ai_generated: true,
      }),
    {
      onSuccess: () => {
        mutate(`/project/idea/brainstorm/${projectId}`);

        setGeneratedIdeas((ideas) =>
          ideas.filter(
            ({ item, description }) =>
              selectedIdeas.findIndex(
                (selectedIdea) =>
                  selectedIdea.item === item &&
                  selectedIdea.description === description
              ) === -1
          )
        );
        setSelectedIdeas([]);
      },
    }
  );

  const handleToggleIdea = ({ description, item }) => {
    const index = selectedIdeas.findIndex(
      (e) => e.item === item && e.description === description
    );
    if (index >= 0) {
      setSelectedIdeas((p) =>
        p.filter((e) => !(e.item === item && e.description === description))
      );
    } else {
      let newObj = { description, item };
      setSelectedIdeas((p) => [...p, newObj]);
    }
  };

  const handleAddToGeneratedIdeas = ({ index, newItems }) => {
    setGeneratedIdeas((previousIdeas) => [
      ...previousIdeas.slice(0, index + 1),
      ...newItems,
      ...previousIdeas.slice(index + 1),
    ]);
  };

  if (!isResearchIdeaFilledOut)
    return (
      <Tooltip>
        <TooltipTrigger className="btn-offblack opacity-80" disabled={true}>
          Generate Ideas
        </TooltipTrigger>
        <TooltipContent>
          Please fill out your research idea for us to generate ideas.
        </TooltipContent>
      </Tooltip>
    );

  return (
    <>
      <Dialog open={isGenerateIdeasOpen} onOpenChange={setIsGenerateIdeasOpen}>
        <DialogTrigger className="btn-offblack">Generate Ideas</DialogTrigger>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Add a brainstorming idea</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          {generatedIdeas?.length ? (
            <div className="flex flex-col gap-y-4">
              <ScrollArea className="max-h-80">
                {generatedIdeas?.ideas?.length === 0 && (
                  <span>There are no more generated ideas</span>
                )}
                {isGeneratedIdeasMutating ? (
                  <span className="flex items-center gap-x-2">
                    <LoadingSpinner /> Generating ideas. This may take a few
                    seconds...
                  </span>
                ) : generatedIdeasError ? (
                  <span>
                    There was an error generating ideas. Please try again.
                  </span>
                ) : (
                  <div className="flex flex-col gap-y-2 p-1">
                    {generatedIdeas?.map((idea, i) => {
                      const isSelected = selectedIdeas.find(
                        (e) =>
                          e.item === idea.item &&
                          e.description === idea.description
                      );
                      return (
                        <Idea
                          handleAddToGeneratedIdeas={handleAddToGeneratedIdeas}
                          projectId={projectId}
                          handleToggleIdea={handleToggleIdea}
                          {...idea}
                          index={i}
                          key={i}
                          isSelected={isSelected}
                        />
                      );
                    })}
                  </div>
                )}
              </ScrollArea>

              <div className="flex justify-between gap-4 w-full">
                <div className="flex gap-4">
                  <button
                    disabled={isGeneratedIdeasMutating}
                    className="btn-fuchsia place-self-end self-end justify-self-end"
                    onClick={generateNewIdeas}
                  >
                    {isGeneratedIdeasMutating ? (
                      <>
                        Regenerating <LoadingSpinner />
                      </>
                    ) : (
                      "Regenerate Ideas"
                    )}
                  </button>
                </div>
                {isAddingGeneratedIdeas ? (
                  <button
                    disabled={true}
                    className="btn-fuchsia flex items-center gap-x-2"
                  >
                    Updating <LoadingSpinner />
                  </button>
                ) : (
                  <button
                    disabled={selectedIdeas.length === 0}
                    className="btn-primary"
                    onClick={addGeneratedIdeas}
                  >
                    Add ({selectedIdeas.length})
                  </button>
                )}
              </div>
              {addGenerateIdeasError && (
                <span className="text-red-500">
                  There was an error updating your ideas. Please try again.
                </span>
              )}
            </div>
          ) : (
            <div className="border-dashed border-2 flex flex-col justify-center items-center rounded-lg p-4 gap-y-4">
              <button
                className="btn-primary"
                onClick={generateNewIdeas}
                disabled={isGeneratedIdeasMutating}
              >
                {isGeneratedIdeasMutating ? (
                  <>
                    Generating Ideas <LoadingSpinner />
                  </>
                ) : (
                  "Generate Brainstorm Ideas"
                )}
              </button>
              <span className="text-base">
                Note: this may take a few seconds
              </span>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sorry, you are out of credits!</DialogTitle>
            <DialogDescription>
              Please upgrade to our pro tier to continue using this feature.
              Your credits will also refresh on the first of every month.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center">
            <Link
              className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
              to="/settings/billing"
            >
              Upgrade to Pro
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
