import { FaExternalLinkAlt, FaRegBookmark } from "react-icons/fa";
import { unbookmark_project_call } from "api";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { mutate } from "swr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { remove_timestamp } from "@/lib/utils";
import { BookmarkIcon, ExternalLinkIcon } from "lucide-react";

export default function Call({
  agency,
  call_id,
  close_date,
  description,
  bookmarked,
  title,
  url,
  posted_date,
  award_ceiling,
  projectId,
}) {
  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmark,
  } = useSWRMutation(
    `unbookmark_call/${projectId}/${call_id}`,
    () => unbookmark_project_call({ project_id: projectId, call_id: call_id }),
    {
      onSuccess: () => {
        mutate(
          `get_project_bookmarked_calls/${projectId}`,
          (d) => d.filter((call) => call.call_id !== call_id),
          {
            revalidate: false,
          }
        );
      },
    }
  );

  const newCloseDate = remove_timestamp(close_date);
  const newPostedDate = remove_timestamp(posted_date);

  return (
    <Dialog>
      <DialogTrigger>
        <div className="flex flex-col border-[1px] rounded-lg p-4 gap-y-2 hover:border-fuchsia text-left">
          <div>
            <span className="text-2xl underline font-semibold">{title}</span>
          </div>
          <div>{agency}</div>
          <div className="flex flex-wrap gap-x-4 items-center">
            {posted_date && (
              <div className="text-base">
                <span className="font-semibold">Posted: </span>
                {remove_timestamp(posted_date)}
              </div>
            )}
            {close_date && (
              <div className="text-base">
                <span className="font-semibold">Closes: </span>
                {remove_timestamp(close_date)}
              </div>
            )}
            {award_ceiling && (
              <span className="border-1 border-green-500 bg-green-200 text-xs p-1 rounded-lg">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(award_ceiling)}
              </span>
            )}
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-screen overflow-y-scroll">
        <div className="flex gap-x-2">
          <button
            className="btn-secondary"
            onClick={unbookmark}
            disabled={isUnbookmarking}
          >
            Unbookmark{" "}
            {isUnbookmarking ? (
              <LoadingSpinner />
            ) : (
              <BookmarkIcon className="w-4 h-4" />
            )}
          </button>
          {url && (
            <a className="btn-secondary hover:opacity-60" href={url}>
              Open Link <ExternalLinkIcon className="w-4 h-4" />
            </a>
          )}
        </div>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="text-black flex flex-col gap-y-2">
          <div>{agency}</div>
          <div className="flex flex-wrap gap-x-4 items-center">
            {posted_date && (
              <div className="text-base">
                <span className="font-semibold">Posted: </span>
                {remove_timestamp(posted_date)}
              </div>
            )}
            {close_date && (
              <div className="text-base">
                <span className="font-semibold">Closes: </span>
                {remove_timestamp(close_date)}
              </div>
            )}
            {award_ceiling && (
              <span className="border-1 border-green-500 bg-green-200 text-xs p-1 rounded-lg">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(award_ceiling)}
              </span>
            )}
          </div>
          <div className="break-wordsp-2 text-base">{description}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
