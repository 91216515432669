import Markdown from "react-markdown";
import { MdFace, MdFace4 } from "react-icons/md";
import AddMessageToIdeas from "./AddMessageToIdeas";

export default function InitiumChatMessage({
  projectId,
  sender,
  message,
  id,
  num_replies,
  background,
  sessionId,
  finished,
}) {
  const isSender1 = sender === "Researcher 1";

  return (
    <div className="flex gap-x-1 items-end group relative">
      <div className="translate-y-2">
        {isSender1 ? (
          <MdFace4 className="w-8 h-8" />
        ) : (
          <MdFace className="w-8 h-8" />
        )}
      </div>
      <div className="bg-pastel-blue p-2 rounded-lg rounded-es-none mr-10">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold text-base text-blue">{sender}</div>
            <div className="font-semibold text-blue capitalize text-xs">
              {background}
            </div>
          </div>{" "}
        </div>
        <div className="text-sm">
          <Markdown>{message}</Markdown>
        </div>
      </div>
      <div className="flex items-center p-1 absolute gap-x-2 top-0 right-0 group-hover:visible invisible border-1 bg-white rounded-lg">
        <AddMessageToIdeas
          projectId={projectId}
          sender={sender}
          message={message}
        />
      </div>
    </div>
  );
}
