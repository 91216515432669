import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { add_required_file, recommend_required_files } from "api";
import { LoadingSpinner } from "components";
import { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function RecommendedFilesTab({ projectId, requiredFiles }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [
    filteredRecommendedRequiredFiles,
    setFilteredRecommendedRequiredFiles,
  ] = useState([]);

  const {
    data: recommendedRequiredFiles,
    error: recommendRequiredFilesError,
    isLoading: isRecommendedRequiredFilesLoading,
  } = useSWR(
    `recommend_required_files/${projectId}`,
    () => recommend_required_files({ project_id: projectId }),
    {
      onSuccess: (files) => {
        setFilteredRecommendedRequiredFiles(
          files.filter(
            ({ name, description }) =>
              requiredFiles.findIndex(
                (file) =>
                  file.file_info == name && file.description == description
              ) === -1
          )
        );
      },
    }
  );

  const { trigger: addFiles } = useSWRMutation(
    `add_required_file`,
    async () => {
      await Promise.all(
        selectedFiles.map(({ name, description }) =>
          add_required_file({
            project_id: projectId,
            name: name,
            description: description,
          })
        )
      );
    },
    {
      onSuccess: () => {
        mutate(`get_required_files/${projectId}`);
        setSelectedFiles([]);
      },
    }
  );

  const handleToggleFile = ({ name, description }) => {
    if (
      selectedFiles.findIndex(
        (file) => file.name === name && file.description === description
      ) === -1
    ) {
      setSelectedFiles((files) => [...files, { name, description }]);
    } else {
      setSelectedFiles((files) =>
        files.filter(
          (file) => file.name !== name || file.description !== description
        )
      );
    }
  };

  useEffect(() => {
    setFilteredRecommendedRequiredFiles(
      recommendedRequiredFiles?.filter(
        ({ name, description }) =>
          requiredFiles.findIndex(
            (file) => file.file_info == name && file.description == description
          ) === -1
      ) || []
    );
  }, [requiredFiles]);

  if (isRecommendedRequiredFilesLoading)
    return (
      <div className="flex justify-end">
        <button
          disabled={true}
          className="flex items-center gap-x-2 opacity-70"
        >
          Loading recommended files <LoadingSpinner />
        </button>
      </div>
    );

  return (
    <>
      <ScrollArea className="pr-2 ">
        {filteredRecommendedRequiredFiles?.length > 0 ? (
          <div className="flex flex-col gap-y-4 max-h-96 p-2">
            {filteredRecommendedRequiredFiles?.map(({ description, name }) => (
              <div
                key={name}
                className="rounded-md p-1 gap-x-1 flex bg-pastel-blue hover:ring-2 hover:cursor-pointer ring-fuchsia text-left w-full"
                onClick={() => handleToggleFile({ description, name })}
              >
                <Checkbox
                  className="bg-white mt-1"
                  checked={
                    selectedFiles.findIndex(
                      (file) =>
                        file.name === name && file.description === description
                    ) >= 0
                  }
                />
                <div>
                  <div className="font-semibold">{name}</div>
                  <div className="">{description}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span>We do not have any suggested files.</span>
        )}
      </ScrollArea>
      <div className="flex justify-end">
        <button
          className="btn-primary"
          onClick={addFiles}
          disabled={selectedFiles.length === 0}
        >
          Add ({selectedFiles.length})
        </button>
      </div>
    </>
  );
}
