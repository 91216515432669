import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import Select from "react-select";

const applicantOptions = [
  { value: 99, label: "Unrestricted" },
  { value: 0, label: "State governments" },
  { value: 1, label: "County governments" },
  { value: 2, label: "City or township governments" },
  { value: 4, label: "Special district governments" },
  { value: 5, label: "Independent school districts" },
  {
    value: 6,
    label: "Public and State controlled institutions of higher education",
  },
  {
    value: 7,
    label: "Native American tribal governments (Federally recognized)",
  },
  { value: 8, label: "Public housing authorities/Indian housing authorities" },
  { value: 11, label: "Native American tribal organizations " },
  {
    value: 12,
    label:
      "Nonprofits having a 501 (c) (3) status with the IRS, other than institutions of higher education",
  },
  {
    value: 13,
    label:
      "Nonprofits that do not have a 501 (c) (3) status with the IRS, other than institutions of higher education",
  },
  { value: 20, label: "Private institutions of higher education" },
  { value: 21, label: "Individuals" },
  { value: 22, label: "For-profit organizations other than small businesses" },
  { value: 23, label: "Small businesses" },
  { value: 25, label: "Others" },
];

export default function EligibleApplicants({
  eligibleApplicantTypes,
  setEligibleApplicantsTypes,
}) {
  const handleOnChange = (v, a) => {
    const { action } = a;
    if (action === "select-option") {
      const {
        option: { value },
      } = a;
      setEligibleApplicantsTypes(
        eligibleApplicantTypes ? [...eligibleApplicantTypes, value] : [value]
      );
    } else if (action === "remove-value") {
      const {
        removedValue: { value },
      } = a;
      setEligibleApplicantsTypes(
        eligibleApplicantTypes
          ? eligibleApplicantTypes.filter((v) => v !== value)
          : []
      );
    } else if (action === "clear") {
      setEligibleApplicantsTypes([]);
    }
  };

  const value =
    eligibleApplicantTypes?.map((i) =>
      applicantOptions.find((a) => a.value === i)
    ) || [];

  return (
    <div>
      <Tooltip>
        <TooltipTrigger showIcon={true}>
          <span className="font-semibold">
            Institution Eligibility for Grant Applications
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-lg shadow-2xl border-1">
          This category helps us determine which grants you may be eligible for,
          based on your institution. Please check all the options that apply.
          If, for example, you work in a State Government, you can check “State
          Governments.” If instead you want to search grant applications,
          regardless of eligibility, leave this blank. Note: the category
          “Other” makes up the majority of our grants at this time.
        </TooltipContent>
      </Tooltip>

      <div className="flex flex-col w-full gap-y-2 max-w-full">
        <Select
          className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
          menuPosition="fixed"
          classNames={{
            container: ({ isFocused }) =>
              `${
                isFocused
                  ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                  : ""
              }`,
          }}
          styles={{
            control: () => {},
          }}
          components={{
            DropdownIndicator: null,
            ClearIndicator: null,
          }}
          closeMenuOnSelect={false}
          isClearable
          isMulti
          onChange={handleOnChange}
          options={applicantOptions}
          value={value}
          placeholder={"Leave empty to search all categories"}
        />
      </div>
    </div>
  );
}
