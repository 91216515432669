import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const createOption = (label) => ({
  label,
  value: label,
});

export default function UserInterests({ interests, setInterests }) {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(
    interests
      ? interests.map((t) => {
          return { label: t, value: t };
        })
      : []
  );

  useEffect(() => {
    setValue(
      interests
        ? interests.map((t) => {
            return { label: t, value: t };
          })
        : []
    );
  }, [interests]);

  const [isError, setIsError] = useState(false);

  const handleKeyDown = async (e) => {
    if (isError) setIsError(false);
    if (!inputValue) return;
    if (e.key === "Enter" || e.key === "Tab") {
      if (value.find((v) => v.value === inputValue)) {
        setIsError(true);
        return;
      }

      setValue((p) => [...p, createOption(inputValue)]);
      setInterests(interests ? [...interests, inputValue] : [inputValue]);
      setInputValue("");
      e.preventDefault();
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setInputValue(newValue);
    } else if (context.action === "input-blur") {
      if (!newValue) return;
      if (value.find((v) => v.value === inputValue)) {
        setIsError(true);
        return;
      }

      setValue((p) => [...p, createOption(inputValue)]);
      setInterests(interests ? [...interests, inputValue] : [inputValue]);
      setInputValue("");
    }
  };

  const handleDeleteKeyTopics = async (newValue, context) => {
    // Delete key topic from cache
    if (context.action === "remove-value") {
      const interest = context.removedValue.value;
      setInterests(interests.filter((v) => v !== interest));
    }
    setValue(newValue);
  };

  return (
    <div>
      <div className="flex gap-x-2">
        <Tooltip>
          <TooltipTrigger showIcon={true}>
            <span className="font-semibold">Research Interests</span>
          </TooltipTrigger>
          <TooltipContent className="max-w-lg">
            Here is a spot to add any general research topics that may interest
            you. Examples can range from Artificial Intelligence to
            Biotechnology to Agriculture.
          </TooltipContent>
        </Tooltip>
      </div>

      <div className="flex flex-col w-full gap-y-2 max-w-full">
        <CreatableSelect
          className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
          classNames={{
            container: ({ isFocused }) =>
              `${
                isFocused
                  ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                  : ""
              }`,
          }}
          styles={{
            control: () => {},
          }}
          components={{
            DropdownIndicator: null,
            ClearIndicator: null,
          }}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={handleDeleteKeyTopics}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            setIsError(false);
          }}
          placeholder="Add an interest..."
          inputValue={inputValue}
          value={value}
        />
        {isError && (
          <span className="text-red-500 text-sm">
            There was an error adding your interest. Please try again.
          </span>
        )}
      </div>
    </div>
  );
}
