"use client";
import React, { useEffect, useRef } from "react";
import InfiniteScroll from "@/components/ui/infinite-scroll";
import { Loader2 } from "lucide-react";

// interface DummyProductResponse {
//   products: DummyProduct[];
//   total: number;
//   skip: number;
//   limit: number;
// }

// interface DummyProduct {
//   id: number;
//   title: string;
//   price: string;
// }

const Product = ({ product }) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg border-2 border-gray-200 p-2">
      <div className="flex gap-2">
        <div className="flex flex-col justify-center gap-1">
          <div className="font-bold text-primary">
            {product.id} - {product.title}
          </div>
          <div className="text-sm text-muted-foreground">{product.price}</div>
        </div>
      </div>
    </div>
  );
};

const InfiniteScrollDemo = () => {
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const chatRef = useRef(null);

  useEffect(() => {
    console.log("products changed");
    if (chatRef.current) {
      console.log("scrolling", chatRef.current);
      chatRef.current.scrollBy(0, 100);
    }
  }, [products]);

  const next = async () => {
    if (loading) return;
    setLoading(true);

    /**
     * Intentionally delay the search by 800ms before execution so that you can see the loading spinner.
     * In your app, you can remove this setTimeout.
     **/
    setTimeout(async () => {
      const res = await fetch(
        `https://dummyjson.com/products?limit=3&skip=${
          3 * page
        }&select=title,price`
      );
      const data = await res.json();
      setProducts((prev) => [...prev, ...data.products]);
      setPage((prev) => prev + 1);

      // Usually your response will tell you if there is no more data.
      if (data.products.length < 3) {
        setHasMore(false);
      }
      setLoading(false);
    }, 800);
  };
  return (
    <div className="max-h-[300px] w-full  overflow-y-auto px-10">
      <button className=""></button>
      <div className="flex w-full flex-col items-center gap-3" ref={chatRef}>
        <InfiniteScroll
          hasMore={hasMore}
          isLoading={loading}
          next={next}
          threshold={0}
          reverse={true}
        >
          {hasMore && <Loader2 className="my-4 h-8 w-8 animate-spin" />}
        </InfiniteScroll>
        {products.toReversed().map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default InfiniteScrollDemo;
