import { PageHelp } from "@/components/styled/pageHelp";
import { get_profile, get_project } from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import useSWR from "swr";
import ChooseACall from "./choose-a-call/ChooseACall";
import { useParams } from "react-router-dom";
import SelectedCall from "./selected-call/SelectedCall";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function SelectCall() {
  const { projectId } = useParams();

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  if (isProjectLoading || isEditorLoading) return <LoadingPage />;
  if (isProjectError || editorError) return <ErrorPage />;

  return (
    <div className="flex flex-col h-full gap-y-4">
      <div className="flex w-full justify-between">
        <Title className="text-4xl" value="Selected Call" />
        <PageHelp
          title="Admin Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Admin Space facilitates collaboration and ensures timely
                submission of your grant application.
              </div>
              <div>
                <h2 className="font-semibold">Selected Call</h2>
                View all details about the grant you are applying for. If you
                haven't selected a call yet, you can do so here.
              </div>
              <div>
                <h2 className="font-semibold">Action Items</h2>
                Manage tasks with a to-do list for each project. Create action
                items, assign them to users, set deadlines, and receive email
                reminders for pending tasks.
              </div>
              <div>
                <h2 className="font-semibold">Required Files</h2>
                Track all necessary files and their statuses in one place. Once
                a grant is selected in the Grant Space, we can also recommend
                which files are needed, reducing the time spent on managing
                document requirements.
              </div>
            </div>
          }
        />
      </div>
      {project.selected_grant_id ? (
        <SelectedCall
          projectId={projectId}
          grantId={project.selected_grant_id}
          hasEditorAccess={hasEditorAccess}
        />
      ) : (
        <ChooseACall projectId={projectId} hasEditorAccess={hasEditorAccess} />
      )}
    </div>
  );
}
