import { ErrorPage, LoadingPage, Title } from "components";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { get_project, get_profile, get_general } from "api";
import { useState } from "react";
import { PageHelp } from "@/components/styled/pageHelp";
import Search from "./Search";
import Results from "./Results";
import { get_recommended_calls } from "api/grant_space";
import { useIsEditor } from "@/hooks/use-is-editor";

export default function RecommendedCalls() {
  const { projectId } = useParams();

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  const {
    data: calls,
    isLoading: isCallsLoading,
    error: callsError,
  } = useSWR(
    `get_recommended_calls/${projectId}`,
    () => get_recommended_calls({ project_id: projectId }),
    {
      onSuccess: (d) => setIsShowingResults(d?.calls?.length > 0),
    }
  );

  const {
    data: researchIdea,
    isLoading: isResearchIdeaLoading,
    error: isResearchIdeaError,
  } = useSWR(`/project/idea/general/${projectId}`, () =>
    get_general({ project_id: projectId, tile_id: 4 })
  );

  const [isShowingResults, setIsShowingResults] = useState(
    calls?.calls?.length > 0 || false
  );

  if (isEditorLoading || isCallsLoading || isResearchIdeaLoading)
    return <LoadingPage />;
  if (editorError || callsError || isResearchIdeaError) return <ErrorPage />;

  return (
    <div className="flex flex-col h-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Recommendations" />
        <PageHelp
          title="Grant Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Grant Space is dedicated to discovering and managing funding
                opportunities.
              </div>
              <div>
                <h2 className="font-semibold">Recommendations</h2>
                Using the information you provided in the Research Idea and
                Background Material sections of the Ideation Space, we will
                suggest funding opportunities we think may be relevant to you.
              </div>
              <div>
                <h2 className="font-semibold">Search</h2>A search tool for
                finding calls.
              </div>
              <div>
                <h2 className="font-semibold">Bookmarks </h2>
                Keep track of important calls by bookmarking them. This section
                shows all calls bookmarked by you and any other collaborators on
                the project.
              </div>
            </div>
          }
        />
      </div>
      {!researchIdea?.idea || !researchIdea?.description ? (
        <div>
          Please fill out your{" "}
          <Link
            className="text-fuchsia hover:underline"
            to={`/project/${projectId}/ideation-space`}
          >
            research idea and description
          </Link>{" "}
          to get recommended calls.
        </div>
      ) : (
        <>
          {isShowingResults ? (
            <Results
              projectId={projectId}
              calls={calls.calls}
              setIsShowingResults={setIsShowingResults}
            />
          ) : (
            <Search
              hasEditorAccess={hasEditorAccess}
              projectId={projectId}
              calls={calls.calls}
              setIsShowingResults={setIsShowingResults}
            />
          )}
        </>
      )}
    </div>
  );
}
