import axiosInstance from "./axiosInstance";

export const get_general = async ({ project_id, tile_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/general",
    method: "GET",
    params: {
      project_id: project_id,
      tile_id: tile_id,
    },
  });
  return response.data;
};

export const update_general = async ({
  project_id,
  tile_id,
  idea,
  description,
}) => {
  const response = await axiosInstance({
    url: "/project/idea/general",
    method: "POST",
    data: {
      project_id: project_id,
      tile_id: tile_id,
      idea: idea,
      description: description,
    },
  });
  return response.data;
};

export const get_background_material = async ({ project_id, rec_type }) => {
  const response = await axiosInstance({
    url: "/project/idea/background_materials",
    method: "GET",
    params: {
      project_id: project_id,
      rec_type: rec_type,
    },
  });
  return response.data;
};

export const find_calls = async ({
  project_id,
  min_award_floor,
  cost_sharing,
  closing_date,
  category_of_funding,
}) => {
  const response = await axiosInstance({
    url: "/process/calls/recommend",
    method: "POST",
    data: {
      project_id: project_id,
      min_award_floor: min_award_floor,
      cost_sharing: cost_sharing,
      closing_date: closing_date,
      category_of_funding: category_of_funding,
    },
  });
  return response.data;
};

export const get_user_pinned_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/calls",
    method: "GET",
    params: {
      project_id: project_id,
      user_pinned: true,
    },
  });
  return response.data;
};

export const get_project_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const get_all_calls = async () => {
  const response = await axiosInstance({
    url: "/project/idea/calls",
    method: "GET",
    params: {
      user_bookmarked: true,
    },
  });
  return response.data;
};

export const get_papers = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/paper/info",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const generate_key_topics = async ({ project_id, paper_type }) => {
  const response = await axiosInstance({
    url: "/process/idea/key_topics",
    method: "POST",
    data: {
      project_id: project_id,
      paper_type: paper_type,
    },
  });
  return response.data;
};

export const add_key_topic = async ({ project_id, key_topic, paper_type }) => {
  const response = await axiosInstance({
    url: "/project/idea/key_topics",
    method: "POST",
    data: {
      project_id: project_id,
      key_topic: key_topic,
      paper_type: paper_type,
    },
  });
  return response.data;
};

export const remove_key_topic = async ({
  project_id,
  key_topic,
  paper_type,
}) => {
  const response = await axiosInstance({
    url: "/project/idea/key_topics",
    method: "DELETE",
    data: {
      project_id: project_id,
      key_topic: key_topic,
      paper_type: paper_type,
    },
  });
  return response.data;
};

export const get_brainstorm = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/idea",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} project_id
 * @param {*} brainstorm_ideas Array of ideas
 * @param {*} ai_generated Boolean
 * @returns
 */
export const add_brainstorm_ideas = async ({
  project_id,
  brainstorm_ideas,
  ai_generated,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/idea",
    method: "POST",
    data: {
      project_id: project_id,
      ideas: brainstorm_ideas,
      ai_generated: ai_generated,
    },
  });
  return response.data;
};

export const delete_brainstorm_idea = async ({ project_id, brainstorm_id }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/idea",
    method: "DELETE",
    data: {
      project_id: project_id,
      brainstorm_id: brainstorm_id,
    },
  });
  return response.data;
};

export const update_brainstorm_idea = async ({
  project_id,
  brainstorm_idea,
}) => {
  await axiosInstance({
    url: "/project/brainstorm/idea",
    method: "POST",
    data: {
      project_id: project_id,
      ideas: [brainstorm_idea],
      edit_ideas: true,
    },
  });
  return brainstorm_idea;
};

export const generate_brainstorm_ideas = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/idea/create",
    method: "POST",
    data: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const get_brainstorm_sessions = async ({
  project_id,
  start_number,
  num_session,
  finished,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/list",
    method: "GET",
    params: {
      project_id: project_id,
      start_number: start_number,
      num_sessions: num_session,
      finished: finished,
    },
  });
  return response.data;
};

export const create_new_brainstorm_session = async ({
  project_id,
  name,
  agent_1_background,
  agent_2_background,
  project_grounded,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/create",
    method: "POST",
    data: {
      project_id: project_id,
      name: name,
      agent_1_background: agent_1_background,
      agent_2_background: agent_2_background,
      project_grounded: project_grounded,
    },
  });
  return response.data;
};

export const create_followup_session = async ({
  project_id,
  session_id,
  name,
  agent_1_background,
  agent_2_background,
  project_grounded,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/create/follow_up",
    method: "POST",
    data: {
      session_id: session_id,
      project_id: project_id,
      name: name,
      agent_1_background: agent_1_background,
      agent_2_background: agent_2_background,
      project_grounded: project_grounded,
    },
  });
  return response.data;
};

export const get_brainstorm_session_messages = async ({
  session_id,
  start_number,
  message_id,
}) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/message/get",
    method: "GET",
    params: {
      session_id: session_id,
      start_number: start_number,
      message_id: message_id,
    },
  });
  return response.data;
};

export const send_brainstorm_session_message = async ({
  session_id,
  message,
  start_agent,
}) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/session/message",
    method: "POST",
    data: Object.assign(
      {
        session_id: session_id,
        message: message,
      },
      start_agent && { start_agent }
    ),
  });
  return response.data;
};

export const send_brainstorm_session_message_reply = async ({
  session_id,
  message_id,
  message,
}) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/session/reply",
    method: "POST",
    data: {
      session_id,
      message_id,
      message,
    },
  });
  return response.data;
};

export const update_session_title = async ({ session_id, name }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/name/edit",
    method: "PUT",
    data: {
      session_id: session_id,
      name: name,
    },
  });
  return response.data;
};

export const delete_session = async ({ session_id }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/remove",
    method: "DELETE",
    data: {
      session_id: session_id,
    },
  });
  return response.data;
};

export const finalize_session = async ({ session_id }) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/session/finalize",
    method: "POST",
    data: {
      session_id: session_id,
    },
  });
  return response.data;
};

export const get_brainstorm_session_info = async ({ session_id }) => {
  const response = await axiosInstance({
    url: "/project/brainstorm/session/",
    method: "GET",
    params: {
      session_id: session_id,
    },
  });
  return response.data;
};

export const generate_brainstorm_ideas_from_session = async ({
  session_id,
}) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/session/ideas",
    method: "POST",
    data: {
      session_id: session_id,
    },
  });
  return response.data;
};

export const expand_brainstorm_idea = async ({
  project_id,
  idea,
  feedback,
}) => {
  const response = await axiosInstance({
    url: "/process/brainstorm/idea/expand",
    method: "POST",
    data: {
      project_id: project_id,
      idea: idea,
      feedback: feedback,
    },
  });
  return response.data;
};
