import { Link } from "react-router-dom";

export default function ProjectDetail({ creation_time, project_id, project_owner, project_name, user_access_status }) {
  const date = Date.parse(creation_time);
  const dateString = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(date)
  return (
    <>
      <Link
        className="grid grid-cols-8 text-2xl px-2 py-4 hover:bg-offwhite hover:text-fuchsia"
        to={`/project/${project_id}`}
      >
        <div className="col-span-4">{project_name}</div>
        <div className="col-span-2 capitalize">{project_owner}</div>
        <div className="col-span-2">{dateString}</div>
      </Link>
    </>
  );
}
