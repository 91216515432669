import { BsFillSendFill } from "react-icons/bs";
import { get_chat_uuid, send_chat } from "api";
import useSWRMutation from "swr/mutation";
import { useEffect, useRef, useState } from "react";
import { LoadingSpinner, Title } from "components";
import UserChatMessage from "./UserChatMessage";
import InitiumChatMessage from "./InitiumChatMessage";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ReactComponent as InitiumIcon } from "assets/initium-logos/brandmark-colored.svg";
import { PageHelp } from "@/components/styled/pageHelp";
import { ScrollArea } from "@/components/ui/scroll-area";
import { mutate } from "swr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";

const agency_options = [
  { value: "darpa", label: "DARPA" },
  { value: "doe", label: "DOE" },
  { value: "ies", label: "IES" },
  { value: "nasa", label: "NASA" },
  { value: "nih", label: "NIH" },
  { value: "nih-b", label: "NIH SBIR & STTR" },
  { value: "nih-f", label: "NIH Fellowship" },
  { value: "nih-k", label: "NIH Career Development" },
  { value: "nih-m", label: "NIH Multi Project" },
  { value: "nih-r", label: "NIH Research " },
  { value: "nih-t", label: "NIH Training" },
  { value: "nsf", label: "NSF" },
];

export default function GuidelinesAssistant() {
  const [promptToUpgrade, setPromptToUpgrade] = useState(false);

  const bottomOfChatRef = useRef(null);
  const [message, setMessage] = useState("");
  const [history, setHistory] = useState([]);
  const [agency, setAgency] = useState("");
  const [agencyUUIDs, setAgencyUUIDs] = useState({});

  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [history]);

  const {
    isMutating: isGettingAgencyUUID,
    error: isGettingAgencyUUIDError,
    trigger: getAgencyUUID,
  } = useSWRMutation(`getAgencyUUID/${agency}`, () => get_chat_uuid());

  const {
    isMutating: isSendingMessage,
    error: isSendMessageError,
    trigger: sendMessage,
  } = useSWRMutation(
    `send_chat`,
    (_, { arg: { uuid, message } }) =>
      send_chat({ uuid: uuid, message: message, agency: agency }),
    {
      onSuccess: (response) => {
        if (response.hasOwnProperty("credits_used")) {
          mutate(
            `get_settings`,
            (d) => ({
              ...d,
              credits_left: d.credits_left - response.credits_used,
            }),
            {
              revalidate: false,
            }
          );
        }
        setHistory((p) => [
          ...p,
          {
            ...response,
            agency_name: agency_options.find((v) => v.value === agency).label,
            isUser: false,
          },
        ]);
      },
      onError: (err) => {
        if (err.status === 422) {
          setPromptToUpgrade(true);
        }
      },
      throwOnError: false,
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendingMessage = message;
    setMessage("");
    setHistory((p) => [...p, { isUser: true, text: sendingMessage }]);
    // If we haven't gotten an agency uuid yet, get one and then send the message
    // Else, we just send the message with the current uuid
    if (!(agency in agencyUUIDs)) {
      const { uuid } = await getAgencyUUID();
      setAgencyUUIDs((p) => {
        p[agency] = uuid;
        return p;
      });
      sendMessage({ uuid: uuid, message: sendingMessage });
    } else {
      sendMessage({ uuid: agencyUUIDs[agency], message: sendingMessage });
    }
  };

  return (
    <>
      {!agency ? (
        <>
          <div className="flex justify-between">
            <Title className="text-4xl" value="Agency Guideline Assistant" />
            <PageHelp
              title="Guideline Assistant"
              content={
                <div>
                  The Guideline Assistant is a helpful assistant that can answer
                  any funding questions you may have on different US Government
                  Agencies that fund research. We provide exact references that
                  we used to answer our questions as well so there's less
                  worries about our technology hallucinating.
                </div>
              }
            />
          </div>
          <div className="flex flex-col gap-y-4 justify-center border-2 border-offblack rounded-md p-2 bg-offwhite max-w-lg mx-auto mt-12">
            <div>
              Welcome to <span className="text-fuchsia">Initium.AI</span>'s
              Agency Guideline Assistant.
            </div>
            <div>
              This chat bot is designed to help you answer any questions you may
              have on different government agency's rules as they relate to
              applying for grants. While we try our best to be one hundred
              percent accurate, no one is perfect, so be sure to refer to the
              agency guidelines for any important questions you may have.
            </div>
            <Select onValueChange={(v) => setAgency(v)} value={agency}>
              <SelectTrigger>
                <SelectValue placeholder="Select an Agency" />
              </SelectTrigger>
              <SelectContent>
                {agency_options.map(({ value, label }) => (
                  <SelectItem value={value} key={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </>
      ) : (
        <div className="h-full flex flex-col pb-4">
          <div className="flex justify-between gap-x-8">
            <Select onValueChange={(v) => setAgency(v)} value={agency}>
              <SelectTrigger>
                <SelectValue placeholder="Select an Agency" />
              </SelectTrigger>
              <SelectContent>
                {agency_options.map(({ value, label }) => (
                  <SelectItem value={value} key={value}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <PageHelp
              title="Guideline Assistant"
              content={
                "The Guideline Assistant is a helpful assistant that can answer any funding questions you may have on different US Government Agencies that fund research. We provide exact references that we used to answer our questions as well so there’s less worries about our technology hallucinating. "
              }
            />
          </div>
          <ScrollArea className="grow p-4">
            {history.map((chat, i) =>
              chat.isUser ? (
                <UserChatMessage key={i} {...chat} />
              ) : (
                <InitiumChatMessage key={i} {...chat} />
              )
            )}

            {(isSendingMessage || isGettingAgencyUUID) && (
              <div className="w-2/3 flex gap-x-2 items-end">
                <div className="rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2">
                  <InitiumIcon className="w-8 h-8" />
                </div>
                <div className="bg-pastel-blue flex items-center gap-x-2 px-4 py-1 rounded-2xl rounded-es-none text-sm">
                  <LoadingSpinner /> Loading...
                </div>
              </div>
            )}
            <div ref={bottomOfChatRef}></div>
          </ScrollArea>
          <div>
            <form className="flex w-full gap-x-2" onSubmit={handleSubmit}>
              <input
                className="border-offblack border-2 rounded-lg grow bg-light-grey px-2"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                disabled={
                  message.length === 0 ||
                  agency === "" ||
                  isSendingMessage ||
                  isGettingAgencyUUID
                }
                className="btn-fuchsia flex gap-x-2 items-center"
              >
                Send
                <BsFillSendFill />
              </button>
            </form>
            <div className="text-base text-center text-fuchsia">
              Our robot is a robot. Please check and review all important
              details and info.
            </div>
          </div>
        </div>
      )}
      <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sorry, you are out of credits!</DialogTitle>
            <DialogDescription>
              Please upgrade to our pro tier to continue using this feature.
              Your credits will also refresh on the first of every month.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center">
            <Link
              className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
              to="/settings/billing"
            >
              Upgrade to Pro
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
