import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { update_template_name } from "api/write_space";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function EditTemplateInfo({ templateId, projectId, name }) {
  const [tempTitle, setTempTitle] = useState(name);

  const { trigger: updateTitle } = useSWRMutation(
    "update_title",
    () => update_template_name({ template_id: templateId, name: tempTitle }),
    {
      onSuccess: () => {
        mutate(
          `/write/template/list?${projectId}`,
          (d) =>
            d.map((template) =>
              template.template_id == templateId
                ? { ...template, template_name: tempTitle }
                : template
            ),
          { revalidate: false }
        );
      },
    }
  );

  const handleSave = () => {
    updateTitle();
  };

  return (
    <Dialog>
      <DialogTrigger className="hover:text-fuchsia text-2xl">{name}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Template</DialogTitle>
          <DialogDescription>
            Edit your template information below.
          </DialogDescription>
        </DialogHeader>
        <Input
          value={tempTitle}
          onChange={(e) => setTempTitle(e.target.value)}
        />
        <div className="flex justify-end">
          <DialogClose asChild>
            <button
              onClick={handleSave}
              className="text-sm btn-fuchsia"
              disabled={tempTitle === name}
            >
              Save
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
