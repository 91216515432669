import { HiUserCircle } from "react-icons/hi2";
import Markdown from "react-markdown";

export default function UserChatMessage({ sender, message }) {
  return (
    <div className="flex items-end justify-end ml-10 gap-x-1">
      <div className="bg-blue text-white rounded-lg rounded-ee-none p-2">
        <div className="text-sm">
          <Markdown>{message}</Markdown>
        </div>
      </div>
      <div className="translate-y-4">
        <HiUserCircle className="w-8 h-8" />
      </div>
    </div>
  );
}
