import axiosInstance from "./axiosInstance";


export const count_project = async ({ ownership }) => {
  const response = await axiosInstance({
    url: "/project/count",
    method: "GET"
  });
  return response.data;
}

/**
 * 
 * @param {*} start_number Optional
 * @param {*} num_projects Optional
 * @param {*} ownership Optional: all, me, or shared
 * @returns 
 */
export const list_projects = async ({ start_number, num_projects, ownership }) => {
  const response = await axiosInstance({
    url: "/project/list_projects",
    method: "GET",
    params: {
      start_number: start_number,
      num_projects: num_projects,
      ownership: ownership
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} project_id Required 
 * @returns 
 */
export const get_project = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/info",
    method: "GET",
    params: {
      project_id: project_id
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} project_id Required
 * @param {*} name Required
 * @returns 
 */
export const update_project_name = async ({ project_id, name }) => {
  const response = await axiosInstance({
    url: "/project/name",
    method: "PUT",
    data: {
      project_id: project_id,
      project_name: name,
    }
  })
  return response.data;
}

/**
 * 
 * @param {*} name Required
 * @returns 
 */
export const create_project = async ({ name }) => {
  const response = await axiosInstance({
    url: "/project/create",
    method: "POST",
    data: {
      project_name: name,
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} project_id  Required
 * @returns 
 */
export const delete_project = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/delete",
    method: "delete",
    data: {
      project_id: project_id,
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} new_emails List of emails to share the project with 
 * @param {*} access What access level the new users will have: "viewer" or "editor" 
 * @returns 
 */
export const add_project_roles = async ({project_id, new_emails, access}) => {
  const response = await axiosInstance({
    url: "/project/share",
    method: "POST",
    data: {
      project_id: project_id,
      request_type: "add",
      new_emails: new_emails,
      access: access
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} new_roles A list of json objects each with key user_id and access that gives the user to update and the access level to update to 
 */
export const edit_project_roles = async({project_id, new_roles}) => {
  const response = await axiosInstance({
    url: "/project/share",
    method: "POST",
    data: {
      project_id: project_id,
      request_type: "edit_roles",
      new_roles: new_roles
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} remove_user_id A list of user ids to remove from the project 
 * @returns 
 */
export const remove_project_role = async ({project_id, remove_user_id}) => {
  const response = await axiosInstance({
    url: "/project/share",
    method: "DELETE",
    data: {
      project_id: project_id,
      remove_user_id: remove_user_id
    }
  });
  return response.data;
}

