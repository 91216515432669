import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { TableCell, TableRow } from "@/components/ui/table";
import { remove_timestamp } from "@/lib/utils";
import { delete_template } from "api/write_space";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Template({
  projectId,
  creation_time,
  template_id,
  template_name,
}) {
  const navigate = useNavigate();
  const { trigger: deleteTemplate } = useSWRMutation(
    "delete_dialog",
    async (key, { arg }) => {
      await delete_template({ template_id: arg });
      return arg;
    },
    {
      onSuccess: (template_id) => {
        mutate(
          `/write/template/list?${projectId}`,
          (d) => d.filter((template) => template.template_id !== template_id),
          { revalidate: false }
        );
      },
    }
  );

  return (
    <TableRow
      className="hover:cursor-pointer hover:bg-pastel-blue"
      key={template_id}
      onClick={() =>
        navigate(`/project/${projectId}/write-space/${template_id}`)
      }
    >
      <TableCell>{template_name}</TableCell>
      <TableCell>{remove_timestamp(creation_time)}</TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Dialog>
          <DialogTrigger className="hover:text-red-600 hover:bg-slate-300 transition-colors rounded-lg">
            <MdDelete className="h-5 w-5" />
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete Template</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete this template?
              </DialogDescription>
              <div className="flex justify-between">
                <DialogClose className="text-base hover:underline">
                  Cancel
                </DialogClose>
                <button
                  type="submit"
                  className="text-base text-red-600 hover:underline"
                  onClick={() => deleteTemplate(template_id)}
                >
                  Delete
                </button>
              </div>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
