import axiosInstance from "./axiosInstance";

export const billing_config = async () => {
  const response = await axiosInstance({
    url: "/billing/config",
    method: "GET",
  });
  return response.data;
};

export const get_customer_plan = async () => {
  const response = await axiosInstance({
    url: "/billing/customer-plan",
    method: "GET",
  });
  return response.data;
};

export const create_customer_portal = async () => {
  const response = await axiosInstance({
    url: "/billing/customer-portal",
    method: "POST",
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 303,
  });
  return response.data;
};

export const create_checkout_session = async ({ price_id }) => {
  const response = await axiosInstance({
    url: "/billing/checkout-session",
    method: "POST",
    data: { price_id: price_id },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 303,
  });
  return response.data;
};
