import axiosInstance from "./axiosInstance";
export const get_settings = async () => {
  const response = await axiosInstance({
    url: "/user/settings",
    method: "GET",
  });
  return response.data;
};
export const connect_google_drive = async () => {
  const response = await axiosInstance({
    url: "/user/oauth/google/authorize",
    method: "POST",
  });

  return response.data;
  // return "response_type=code&client_id=549549515103-jl4e7c39dqhmvpri6v9vctqb6in29rmv.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Flocal.initium.ai%2Fgoogle%2Foauth2callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file&state=X6O2gh6ESCN2Emz6Rzgj4hOPMhMshp&access_type=offline&include_granted_scopes=true&prompt=consent";
};

export const disconnect_google_drive = async () => {
  const response = await axiosInstance({
    url: "/user/oauth/google/authorize",
    method: "DELETE",
  });
  return response.data;
};
