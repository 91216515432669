import { Input } from "@/components/ui/input";
import { remove } from "api/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DeleteAccount() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const deleteEmail = async () => {
    const response = await remove({ send_email: true, email: email });
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 font-bold bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          Delete Account
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            disabled={!email.length}
            onClick={deleteEmail}
            className="btn-fuchsia "
          >
            Delete
          </button>
          <button
            onClick={() => {
              navigate("/login", { state: { isFirstVerified: true } });
            }}
          >
            Test Navigation
          </button>
          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
