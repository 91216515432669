import axiosInstance from "./axiosInstance";

export const provide_feedback_for_recommended_calls = async ({
  useful,
  project_id,
}) => {
  const response = await axiosInstance({
    url: "/calls/recommend/feedback",
    method: "POST",
    data: {
      project_id: project_id,
      useful: useful,
    },
  });
  return response.data;
};

export const add_user_call = async ({ project_id, grant_data }) => {
  const response = await axiosInstance({
    url: "/calls/add/manual",
    method: "PUT",
    data: {
      project_id: project_id,
      grant_data: grant_data,
    },
  });
  return response.data;
};

export const get_recommended_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/calls/recommend",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

export const get_project_bookmarked_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};
