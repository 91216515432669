import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { add_new_review } from "api/review_space";
import { LoadingSpinner } from "components";
import { Upload } from "lucide-react";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";

import { FaCloudUploadAlt } from "react-icons/fa";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function UploadFromLocal({ projectId, org_tier }) {
  const closeButtonRef = useRef(null);

  const {
    data: fileData,
    isMutating: isFileUploading,
    error: isFileUploadError,
    trigger: uploadFile,
    reset: resetFile,
  } = useSWRMutation(
    `add_new_review/${projectId}`,
    (key, { arg }) => {
      return add_new_review(arg);
    },
    {
      onSuccess: () => {
        resetFile();
        closeButtonRef?.current.click();
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"], "application/msword": [".docx"] },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
    });

  const onUploadFile = () => {
    const { name, type } = acceptedFiles[0];
    uploadFile({
      project_id: projectId,
      file_type: type,
      file_name: name,
      file: acceptedFiles[0],
    });
  };

  if (org_tier === "starter")
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-sm"
          disabled={org_tier === "starter"}
        >
          <Upload className="h-4 w-4" />
          Upload file
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="btn-fuchsia text-sm">
          <Upload className="h-4 w-4" />
          Upload file
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload a file</DialogTitle>
          <DialogDescription>
            Processing may take around 5-10 minutes.
          </DialogDescription>
        </DialogHeader>
        <>
          <DialogClose className="hidden" ref={closeButtonRef} />
          {isFileUploading ? (
            <div
              className={
                "w-full p-8 border-4 border-dashed rounded-lg flex gap-x-4 items-center justify-center"
              }
            >
              <LoadingSpinner />
              <p className="text-xl">Uploading</p>
            </div>
          ) : (
            <div
              className={
                "w-full p-8 border-4 border-dashed rounded-lg " +
                (isDragActive ? "border-fuchsia" : "border-zinc-200")
              }
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {acceptedFiles.length ? (
                <div className="flex flex-col items-center justify-center ">
                  <FaCloudUploadAlt className="w-20 h-20" />
                  <p className="text-xl">{acceptedFiles[0].path}</p>
                  <span className="h-6"></span>
                  <button
                    className="btn-fuchsia text-xl"
                    onClick={onUploadFile}
                  >
                    Click to upload file
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center ">
                  <FaCloudUploadAlt className="w-20 h-20" />
                  <p className="text-xl">Drag and drop a PDF or DOCX here</p>
                  <p>or</p>
                  <button onClick={open} className="btn-offblack text-xl">
                    Click to browse files
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      </DialogContent>
    </Dialog>
  );
}
