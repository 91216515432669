import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { googleLogin, login } from "api/auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useAuthStore } from "stores/useAuthStore";
import useSWRMutation from "swr/mutation";
import { Input } from "@/components/ui/input";

export default function Login() {
  const { state } = useLocation(); // If redirected from confirming email, isFirstVerified will be set to true.
  const navigate = useNavigate();
  const setRefreshToken = useAuthStore((store) => store.setRefreshToken);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isGoogleLoginError, setIsGoogleLoginError] = useState(false);

  const { error: loginError, trigger: loginInitium } = useSWRMutation(
    `login`,
    () =>
      login({
        email: email,
        password: password,
      }),
    {
      onSuccess: ({ refresh_token }) => {
        setRefreshToken(refresh_token);
        if (state?.isFirstVerified) {
          navigate("/profile");
        } else {
          navigate("/");
        }
      },
      onError: (r) => console.log(r),
    }
  );

  const onGoogleLoginSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;
    try {
      const response = await googleLogin({ tokenId: credential });

      let { refresh_token } = response;
      setRefreshToken(refresh_token);

      if (state?.isFirstVerified) {
        navigate("/profile");
      } else {
        navigate("/");
      }
    } catch (e) {
      setIsGoogleLoginError(true);
      console.error(e);
    }
  };

  const onGoogleLoginError = async () => {
    setIsGoogleLoginError(true);
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          <span className="font-bold">Login</span>
          <form
            className="flex flex-col gap-y-4"
            onSubmit={(e) => {
              e.preventDefault();
              loginInitium();
            }}
          >
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button
              disabled={!email.length || !password.length}
              className="btn-fuchsia justify-center text-xl"
            >
              Log in
            </button>
          </form>
          {loginError && (
            <span className="text-red-500 text-base h-4">
              {loginError.response.data}
            </span>
          )}
          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password
            </button>
          </div>
          <div className="flex justify-center">
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GoogleLogin
                onSuccess={onGoogleLoginSuccess}
                onError={onGoogleLoginError}
                cancel_on_tap_outside
                useOneTap
                use_fedcm_for_prompt={true}
              />
            </GoogleOAuthProvider>
            {isGoogleLoginError && (
              <span className="text-red-500 text-base h-4">
                An unexpected issue occurred. Please try again.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
