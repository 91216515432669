import { useState } from "react";
import { useLocation } from "react-router-dom";
import { verifyUser } from "api/auth";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";

export default function VerifyEmail() {
  // If redirected from registering, isEmailSent will be set to true and email will be set
  const { state } = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState(state?.email || "");
  const [isSent, setIsSent] = useState(state?.isEmailSent || false);
  const [errorMessage, setErrorMessage] = useState("");

  const resendVerification = async () => {
    setErrorMessage("");
    try {
      await verifyUser({ resend: true, email: email });
      setIsSent(true);
    } catch (e) {
      setIsSent(false);
      setErrorMessage(
        "An unexpected error occurred. Please make sure you entered the correct email."
      );
    }
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10  bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          <span className="font-bold">Email Verification</span>

          {isSent ? (
            <div className="flex flex-col gap-y-4">
              <p>
                Verification email was to{" "}
                <span className="text-fuchsia">{email}</span>! Please check your
                email!
              </p>
              <button className="btn-fuchsia" onClick={() => setIsSent(false)}>
                Send another email
              </button>
            </div>
          ) : (
            <form
              className="flex flex-col gap-y-4"
              onSubmit={(e) => {
                e.preventDefault();
                resendVerification();
              }}
            >
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button disabled={!email} className="btn-fuchsia">
                Send Email
              </button>
              <span className="text-red-500">
                {errorMessage.length > 0 && errorMessage}
              </span>
            </form>
          )}

          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
