import { MdOutlineExitToApp } from "react-icons/md";
import Heading from "./Heading";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { generate_outline } from "api/write_space";
import { mutate } from "swr";
import { ScrollArea } from "@/components/ui/scroll-area";
import RegenerateOutlineModal from "./RegenerateOutlineModal";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { LoadingSpinner } from "components";

export default function DefaultView({
  setIsEditorView,
  templateId,
  projectId,
  headers,
  headerLabels,
  isInitialOutlineGeneration,
}) {
  const [promptToUpgrade, setPromptToUpgrade] = useState(false);
  const { trigger: generateOutline, isMutating: isGeneratingOutline } =
    useSWRMutation(
      "generate_outline",
      () => generate_outline({ template_id: templateId }),
      {
        onSuccess: (headerOutlines) =>
          mutate(
            `/write/template/${projectId}/${templateId}`,
            (headers) =>
              headers.map((header) => ({
                ...header,
                outline: headerOutlines.outline.find(
                  (headerOutline) =>
                    headerOutline.heading_id === header.heading_id
                ).outline,
              })),
            { revalidate: false }
          ),
        onError: (err) => {
          if (err.status === 422) {
            setPromptToUpgrade(true);
          }
        },
        throwOnError: false,
      }
    );

  return (
    <div className="flex flex-col gap-y-2 text-base h-full">
      <div className="flex w-full items-center justify-between">
        <button
          className="text-sm flex items-center gap-x-2 hover:text-fuchsia"
          onClick={() => setIsEditorView(true)}
        >
          <MdOutlineExitToApp />
          Enter Editor Mode
        </button>
        {isInitialOutlineGeneration ? (
          isGeneratingOutline ? (
            <button className="btn-fuchsia text-sm" disabled={true}>
              Creating Outline <LoadingSpinner />
            </button>
          ) : (
            <Tooltip disableHoverableContent={headers?.length}>
              <TooltipTrigger asChild>
                <button
                  disabled={!headers?.length}
                  className="btn-fuchsia text-sm"
                  onClick={generateOutline}
                >
                  Create Outline
                </button>
              </TooltipTrigger>
              <TooltipContent>
                Add headers to generate an outline.
              </TooltipContent>
            </Tooltip>
          )
        ) : (
          <RegenerateOutlineModal
            templateId={templateId}
            projectId={projectId}
            headings={headers}
            headingLabels={headerLabels}
          >
            <span className="btn-fuchsia text-base">Regenerate Outline</span>
          </RegenerateOutlineModal>
        )}
        <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Sorry, you are out of credits!</DialogTitle>
              <DialogDescription>
                Please upgrade to our pro tier to continue using this feature.
                Your credits will also refresh on the first of every month.
              </DialogDescription>
            </DialogHeader>
            <div className="flex justify-center">
              <Link
                className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
                to="/settings/billing"
              >
                Upgrade to Pro
              </Link>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <ScrollArea className="h-full mb-8">
        <div className="h-full flex flex-col gap-y-2 p-2">
          {headers.map((header, i) => (
            <Heading
              templateId={templateId}
              projectId={projectId}
              key={header.heading_id}
              headings={headers}
              label={headerLabels[i]}
              headingLabels={headerLabels}
              {...header}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}
