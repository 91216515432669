import { useIsEditor } from "@/hooks/use-is-editor";
import { get_brainstorm, get_general } from "api";
import { ErrorPage, LoadingPage } from "components";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Idea from "./Idea";
import GenerateBrainstormIdeas from "./generate-ideas/GenerateBrainstormIdeas";
import ManualAddIdea from "./ManualAddIdea";

export default function Ideas() {
  const { projectId } = useParams();

  const {
    data: brainstormIdeas,
    isLoading: isGettingBrainstormIdeas,
    error: brainstormIdeasError,
  } = useSWR(`/project/idea/brainstorm/${projectId}`, () =>
    get_brainstorm({ project_id: projectId })
  );

  const {
    data: researchIdea,
    isLoading: isResearchIdeaLoading,
    error: researchIdeaError,
  } = useSWR(`/project/idea/general/${projectId}`, () =>
    get_general({ project_id: projectId, tile_id: 4 })
  );

  const {
    data: hasEditorAccess,
    isLoading: isEditorLoading,
    error: editorError,
  } = useIsEditor(projectId);

  if (isGettingBrainstormIdeas || isResearchIdeaLoading || isEditorLoading)
    return <LoadingPage />;
  if (brainstormIdeasError || researchIdeaError || editorError)
    return <ErrorPage />;

  const isResearchIdeaFilledOut =
    researchIdea.idea?.length && researchIdea.description?.length;

  return (
    <div className="flex flex-col gap-y-4 pb-8 grow h-full">
      {brainstormIdeas?.items?.length === 0 ? (
        <>
          <div className="text-base">
            Brainstorming Ideas is a dedicated space for users to organize,
            develop, and refine their thoughts. You can add an unlimited number
            of ideas, making it easy to capture all your creative insights. If
            you ever need inspiration or wish to explore new directions, our
            platform can assist by generating relevant ideas tailored to your
            current focus.
          </div>
          <div className="flex flex-col grow border-dashed border-2 h-full py-4 justify-center gap-y-2">
            <div className="flex justify-center">
              <GenerateBrainstormIdeas
                className="w-fit"
                projectId={projectId}
                isResearchIdeaFilledOut={isResearchIdeaFilledOut}
              />
            </div>
            <div className="flex justify-center">or</div>
            <div className="flex justify-center">
              <ManualAddIdea projectId={projectId} />
            </div>
          </div>
        </>
      ) : (
        <>
          {hasEditorAccess && (
            <>
              <div className="flex gap-x-2">
                <GenerateBrainstormIdeas
                  projectId={projectId}
                  isResearchIdeaFilledOut={isResearchIdeaFilledOut}
                />

                <ManualAddIdea projectId={projectId} />
              </div>
            </>
          )}

          {brainstormIdeas.items.map((brainstorm_idea, i) => (
            <Idea
              index={i + 1}
              projectId={projectId}
              key={brainstorm_idea.brainstorm_id}
              {...brainstorm_idea}
              hasEditorAccess={hasEditorAccess}
            />
          ))}
        </>
      )}
    </div>
  );
}
