import { get_project } from "api";
import { get_settings } from "api/settings";
import useSWR from "swr";

export function useIsEditor(projectId) {
  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: settings,
    isLoading: isSettingsLoading,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  if (isProjectLoading || isSettingsLoading)
    return { isLoading: isProjectLoading || isSettingsLoading };
  if (projectError) return { error: projectError };
  if (settingsError) return { error: settingsError };

  const user = project.users.find((user) => user.user_id === settings.user_id);
  if (!user) return false;

  return { data: user.relationship !== "viewer" };
}
