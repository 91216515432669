import { delete_required_file, edit_required_file } from "api";

import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { MdDelete } from "react-icons/md";
import { FaExpand } from "react-icons/fa";
import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { useState } from "react";

export default function File({
  file_id,
  file_info,
  completed,
  description,
  projectId,
  hasEditorAccess,
}) {
  const [tempFileInfo, setTempFileInfo] = useState(file_info);
  const [tempDescription, setTempDescription] = useState(description);

  const { error: toggleCompleteError, trigger: toggleComplete } =
    useSWRMutation(
      `toggle_complete`,
      () =>
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          complete: !completed,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, completed: !completed }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateFileInfoError, trigger: updateFileInfo } =
    useSWRMutation(
      `update_file_info`,
      (key, { arg }) => {
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          name: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_file_info) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, file_info: new_file_info }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateDescriptionError, trigger: updateDescription } =
    useSWRMutation(
      `update_description`,
      (key, { arg }) => {
        if (arg === description) return arg;
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          description: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_description) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, description: new_description }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: deleteRequiredFileError, trigger: deleteRequiredFile } =
    useSWRMutation(
      `delete_required_file`,
      () => delete_required_file({ project_id: projectId, file_id: file_id }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) => d.filter((file) => file.file_id !== file_id),
            { revalidate: false }
          );
        },
      }
    );

  const handleUpdateName = () => {
    if (file_info !== tempFileInfo) updateFileInfo(tempFileInfo);
  };

  const handleUpdateDescription = () => {
    if (description !== tempDescription) updateDescription(tempDescription);
  };

  return (
    <TableRow key={file_id} className="group">
      <TableCell className="text-center">
        <Checkbox
          checked={completed}
          onClick={toggleComplete}
          disabled={!hasEditorAccess}
        />
      </TableCell>
      <TableCell className="relative">
        <Input
          placeholder={"New file"}
          value={tempFileInfo || ""}
          onChange={(e) => setTempFileInfo(e.target.value)}
          onBlur={handleUpdateName}
          maxLength={250}
          readOnly={!hasEditorAccess}
        />
        <Dialog>
          <DialogTrigger className="flex gap-x-2 items-center hover:text-magenta absolute top-1/2 -translate-y-1/2 right-0 invisible group-hover:visible bg-light-grey p-1 rounded-md hover:text-fuchsia">
            OPEN <FaExpand />
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit File Information</DialogTitle>
              <DialogDescription>
                Edit your file information here.
              </DialogDescription>
            </DialogHeader>
            <div>
              <Input
                placeholder={"New file"}
                value={tempFileInfo || ""}
                onChange={(e) => setTempFileInfo(e.target.value)}
                onBlur={handleUpdateName}
                maxLength={250}
                readOnly={!hasEditorAccess}
              />
              <table className="table-auto text-base border-separate border-spacing-x-2 my-2">
                <tbody>
                  <tr>
                    <td className="font-semibold">Completed</td>
                    <td>
                      <Checkbox
                        checked={completed}
                        onClick={toggleComplete}
                        disabled={!hasEditorAccess}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Textarea
                placeholder={"Enter a description..."}
                value={tempDescription || ""}
                onChange={(e) => setTempDescription(e.target.value)}
                onBlur={handleUpdateDescription}
                readOnly={!hasEditorAccess}
              />
              {hasEditorAccess && (
                <button
                  onClick={deleteRequiredFile}
                  className="text-red-500 flex items-center mt-8 text-base hover:underline"
                  disabled={!hasEditorAccess}
                >
                  <MdDelete /> Delete
                </button>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
