import { get_template_info, list_templates } from "api/write_space";
import { ErrorPage, LoadingPage, Title } from "components";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import Editor from "./editor/Editor";
import { useEffect, useState } from "react";
import DefaultView from "./default-view/DefaultView";
import { FaArrowLeft } from "react-icons/fa";
import EditTemplateInfo from "./EditTemplateInfo";

const createSectionHeaders = (list) => {
  if (!list) return [];
  let depthsCounter = [];
  let sectionHeaders = [];
  for (let i = 0; i < list.length; i++) {
    // Initialize missing headers
    if (list[i].depth + 1 > depthsCounter.length) {
      for (let j = depthsCounter.length; j < list[i].depth + 1; j++) {
        depthsCounter.push(0);
      }
    }
    if (list[i].depth + 1 < depthsCounter.length) {
      depthsCounter = depthsCounter.slice(0, list[i].depth + 1);
    }
    depthsCounter[list[i].depth]++;
    sectionHeaders.push(depthsCounter.join(".") + ".");
  }
  return sectionHeaders;
};

export default function DocumentTemplate() {
  const [isEditorView, setIsEditorView] = useState(false);

  const { projectId, templateId } = useParams();

  const {
    data: templates,
    isLoading: isGettingTemplates,
    error: templatesError,
  } = useSWR(`/write/template/list?${projectId}`, () =>
    list_templates({ project_id: projectId })
  );

  const {
    data: headers,
    isLoading: isGettingTemplateInfo,
    error: templateInfoError,
  } = useSWR(`/write/template/${projectId}/${templateId}`, () =>
    get_template_info({
      template_id: templateId,
    })
  );

  const [headerLabels, setHeaderLabels] = useState([]);

  useEffect(() => {
    if (!headers) return;
    let newSectionHeaders = createSectionHeaders(headers);
    setHeaderLabels(newSectionHeaders);
  }, [headers]);

  if (isGettingTemplateInfo || isGettingTemplates) return <LoadingPage />;
  if (templateInfoError || templatesError) return <ErrorPage />;

  const templateInfo = templates?.find(
    ({ template_id }) => template_id == templateId
  );

  const isInitialOutlineGeneration = !headers?.some(
    (header) => header?.outline?.length > 0
  );

  return (
    <div className="bg-white grow rounded-lg h-full">
      <div className="flex items-center gap-x-2">
        <Link
          to={`/project/${projectId}/write-space`}
          className="hover:text-fuchsia"
        >
          <FaArrowLeft />
        </Link>
        <EditTemplateInfo
          name={templateInfo.template_name}
          templateId={templateId}
          projectId={projectId}
        />
      </div>

      {isEditorView ? (
        <Editor
          setIsEditorView={setIsEditorView}
          templateId={templateId}
          projectId={projectId}
          headers={headers}
          headerLabels={headerLabels}
          isInitialOutlineGeneration={isInitialOutlineGeneration}
        />
      ) : (
        <DefaultView
          setIsEditorView={setIsEditorView}
          templateId={templateId}
          projectId={projectId}
          headers={headers}
          headerLabels={headerLabels}
          isInitialOutlineGeneration={isInitialOutlineGeneration}
        />
      )}
    </div>
  );
}
