import axiosInstance from "./axiosInstance";
import axios from "axios";

export const list_reviews = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/file/review/list",
    method: "GET",
    params: {
      project_id: project_id,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} project_id
 * @param {*} file_type "pdf" or "docx"
 * @param {*} file_name Optional
 * @param {*} file The file to upload
 * @param {*} google_file_object Google file object that has the keys: name, id, mimeType
 * @returns URL to make a put request (to upload the actual file)
 */
export const add_new_review = async ({
  project_id,
  file_type,
  file_name,
  file,
}) => {
  const response = await axiosInstance({
    url: "/file/review/add",
    method: "POST",
    data: {
      project_id: project_id,
      file_type: file_type,
      file_name: file_name,
    },
  });

  const { upload_url } = response.data;
  await axios({
    url: upload_url,
    method: "PUT",
    headers: {
      "x-goog-content-length-range": "0,10000000",
      "Content-Type": file_type,
    },
    data: file,
  });
  return true;
};

/**
 *
 * @param {*} project_id
 * @param {*} google_file_object Google file object that has the keys: name, id, mimeType
 * @returns
 */
export const add_new_review_via_google = async ({
  project_id,
  google_file_object,
}) => {
  const response = await axiosInstance({
    url: "/file/review/add",
    method: "POST",
    data: {
      project_id: project_id,
      google_file_object: google_file_object,
    },
  });

  return response.data;
};

export const get_file_review = async ({ file_id }) => {
  const response = await axiosInstance({
    url: "/file/review/",
    method: "GET",
    params: {
      file_id: file_id,
    },
  });
  return response.data;
};

export const get_file_review_comments = async ({ file_id }) => {
  const response = await axiosInstance({
    url: "/file/review/",
    method: "GET",
    params: {
      file_id: file_id,
      info: "comments",
    },
  });
  return response.data;
};

export const delete_file_review = async ({ file_id }) => {
  const response = await axiosInstance({
    url: "/file/review/remove",
    method: "DELETE",
    data: {
      file_id: file_id,
    },
  });
  return response.data;
};

export const get_file = async ({ url }) => {
  // const response = await axios({
  //   method: "GET",
  //   url: url,
  // });
  return axios.get(url);
  // return response;
};

export const red_team_chat_send_message = async ({
  uuid,
  message,
  file_id,
  section_id,
  reviewer,
}) => {
  const response = await axiosInstance({
    url: "/chat/red_team_review",
    method: "POST",
    data: {
      uuid: uuid,
      message: message,
      file_id: file_id,
      section_id: section_id,
      reviewer: reviewer,
    },
  });
  return response.data;
};
