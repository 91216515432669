import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { ExternalLinkIcon } from "lucide-react";
import { FaQuestionCircle } from "react-icons/fa";
import { ScrollArea } from "../ui/scroll-area";

function p({ title, content }) {
  return (
    <Sheet>
      <SheetTrigger className="hover:text-fuchsia">
        <FaQuestionCircle />
      </SheetTrigger>
      <SheetContent className="flex flex-col justify-between">
        <ScrollArea>
          <SheetHeader>
            <SheetTitle>{title}</SheetTitle>
            <SheetDescription asChild>{content}</SheetDescription>
          </SheetHeader>
          <div>
            <a
              className="btn-secondary w-fit hover:opacity-60 hover:underline"
              href="https://initium-ai.notion.site/Help-Center-13bde571f6a180248b85f4f26031800b"
              target="_blank"
            >
              Open Help Center <ExternalLinkIcon className="inline w-4 h-4" />
            </a>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
export { p as PageHelp };
