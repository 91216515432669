import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { add_recommended_paper } from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Suggestion({
  projectId,
  authors,
  index,
  link,
  published_date,
  summary,
  title,
  recType,
}) {
  const {
    isMutating: isUpdating,
    error: isAddPaperError,
    trigger,
  } = useSWRMutation(
    `add_recommended_paper/${projectId}/${index}`,
    () => add_recommended_paper({ project_id: projectId, rec_id: index }),
    {
      onSuccess: () => {
        setIsRelevant(true);
        mutate(`get_papers?${projectId}`, undefined, { revalidate: false });

        mutate(
          `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
          (d) => ({
            ...d,
            papers: {
              ...d.papers,
              papers: d.papers.papers.filter((p) => p.title !== title),
            },
          })
        );
      },
    }
  );

  const [isRelevant, setIsRelevant] = useState(false);

  const handleSetRelevant = async () => {
    if (!isRelevant) {
      trigger();
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="flex flex-row border-[1px] rounded-lg p-4 hover:border-fuchsia hover:cursor-pointer text-left">
          <div className="basis-4/5 gap-y-1 flex flex-col grow-0">
            <div className="flex flex-row justify-between">
              <span className="text-2xl font-semibold">{title}</span>
            </div>
            <div className="flex gap-x-2 text-base">
              {authors ? authors.join(", ") : ""}
            </div>

            {summary?.length > 0 && (
              <span className="text-base">
                {summary.substring(0, 300) + (summary.length > 300 && "...")}
              </span>
            )}

            {isAddPaperError && (
              <span className="text-red-500">
                There was an issue adding your paper. Please try again.
              </span>
            )}
          </div>
          <div className="basis-1/5 flex flex-col justify-between items-center">
            <div className="font-bold underline">Relevant</div>
            {isUpdating ? (
              <LoadingSpinner />
            ) : (
              <Checkbox
                className="h-6 w-6 hover:border-fuchsia"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetRelevant();
                }}
              />
            )}
            <div></div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-3xl max-h-screen overflow-y-scroll">
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          className="btn-secondary hover:opacity-60 w-fit"
        >
          Open Link
          <FaExternalLinkAlt className="inline w-4 h-4" />
        </a>
        <DialogHeader>
          <DialogTitle>
            <span className="text-2xl font-semibold">{title}</span>
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-2">
          <div>{authors ? authors.join(", ") : ""}</div>
          <div>{published_date}</div>
          <div>{summary}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
