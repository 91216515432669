import { LoadingSpinner } from "components";
import { useEffect } from "react";

export default function LoadingPaper({ title, mutatePapers }) {
  useEffect(() => {
    // Call mutate papers after 10 seconds
    setTimeout(() => mutatePapers?.(), 1000);
  }, [mutatePapers]);
  return (
    <div className="flex flex-col border-[1px] rounded-lg p-4 hover:border-fuchsia text-left gap-y-2">
      <div>{title}</div>
      <div className="flex items-center gap-x-2">
        <LoadingSpinner />
        Uploading...
      </div>
    </div>
  );
}
