import { clsx } from "clsx";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function remove_timestamp(date) {
  if (!date) return "";
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );
  const displayedDate = date ? format(dtDateOnly, "MMMM dd, yyyy") : "";
  return displayedDate;
}
