import { ErrorPage, LoadingPage, Title } from "components";
import useSWR from "swr";
import Pricing from "./pricing/Pricing";
import { billing_config, get_customer_plan } from "api/billing";
import CurrentPlan from "./current-plan/CurrentPlan";
import { get_settings } from "api/settings";
export default function Billing() {
  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const {
    data: customerPlan,
    isLoading: isGettingCustomerPlan,
    error: customerPlanError,
  } = useSWR("get_customer_plan", get_customer_plan);

  const {
    data: billingConfig,
    isLoading: isGettingBillingConfig,
    error: billingConfigError,
  } = useSWR("billing_config", billing_config);

  if (isGettingCustomerPlan || isGettingBillingConfig || isGettingSettings)
    return <LoadingPage />;
  if (customerPlanError || billingConfigError || settingsError)
    return <ErrorPage />;

  const { prices } = billingConfig;
  // TODO: hardcode handling to get monthly plan
  const monthlyPrice = prices.find((p) => p.recurring.interval === "month");

  return (
    <div>
      <Title className="text-4xl" value={"Billing"} />
      <CurrentPlan
        monthlyPrice={monthlyPrice}
        customerPlan={customerPlan}
        prompt_upgrade={settings?.prompt_upgrade}
        org_relationship={settings?.org_relationship}
      />
      {settings?.prompt_upgrade && (
        <Pricing
          monthlyPrice={monthlyPrice}
          org_relationship={settings?.org_relationship}
        />
      )}
    </div>
  );
}
