import { Input } from "@/components/ui/input";
import { reset_password } from "api/auth";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const {
    isMutating,
    error,
    trigger: sendResetPassword,
  } = useSWRMutation(`reset_password`, () =>
    reset_password({
      reset_code: token,
      password: password,
      password_confirm: passwordConfirm,
    })
  );

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 font-bold bg-white rounded-b-2xl items-center flex flex-col gap-y-4 px-4">
          Reset Password
          {isMutating ? (
            <LoadingSpinner className="text-fuchsia h-20 w-20" />
          ) : (
            <form
              className="flex flex-col gap-y-4 w-full"
              onSubmit={(e) => {
                e.preventDefault();
                sendResetPassword();
              }}
            >
              <Input
                placeholder="Password"
                type="password"
                autocomplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                placeholder="Confirm Password"
                type="password"
                autocomplete="new-password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
              <button
                disabled={!password.length && !passwordConfirm.length}
                className="btn-fuchsia "
              >
                Reset
              </button>
              {error && (
                <span className="text-red-500">
                  There was an unexpected issue, please try again.
                </span>
              )}
            </form>
          )}
          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className="text-base link-fuchsia"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
