import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import CreatableSelect from "react-select/creatable";

import { useState } from "react";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useSWRMutation from "swr/mutation";
import { update_paper } from "api";
import { mutate } from "swr";

const createOption = (v) => ({ label: v, value: v });

export default function EditPaper({
  projectId,
  paper_id,
  name = "",
  authors = [],
  initial_text = "",
  handleOnClickCancel,
}) {
  const [tempTitle, setTempTitle] = useState(name);
  const [tempAuthors, setTempAuthors] = useState(authors);
  const [authorValue, setAuthorValue] = useState("");
  const [tempSummary, setTempSummary] = useState(initial_text);

  const isAuthorsEqual =
    JSON.stringify(authors) === JSON.stringify(tempAuthors);

  const { trigger: updatePaper } = useSWRMutation(
    "update_paper",
    () =>
      update_paper({
        project_id: projectId,
        paper_id: paper_id,
        ...(name !== tempTitle && { title: tempTitle }),
        ...(authors !== tempAuthors && { authors: tempAuthors }),
        ...(initial_text !== tempSummary && { abstract: tempSummary }),
      }),
    {
      onSuccess: () =>
        mutate(
          `get_papers?${projectId}`,
          (d) =>
            d.map((paper) =>
              paper.paper_id === paper_id
                ? {
                    ...paper,
                    authors: tempAuthors,
                    initial_text: tempSummary,
                    name: tempTitle,
                  }
                : paper
            ),
          { revalidate: false }
        ),
    }
  );

  const handleKeyDownAuthors = (e) => {
    if (!authorValue) return;
    if (e.key === "Enter" || e.key === "Tab") {
      if (tempAuthors.find((author) => author === authorValue)) {
        return;
      }

      setTempAuthors((p) => [...p, authorValue]);
      setAuthorValue("");
      e.preventDefault();
    }
  };

  const handleInputChangeAuthors = (newValue, context) => {
    if (context.action === "input-change") {
      setAuthorValue(newValue);
    } else if (context.action === "input-blur") {
      if (!newValue) return;
      if (tempAuthors.find((author) => author === authorValue)) {
        return;
      }

      setTempAuthors((p) => [...p, authorValue]);
      setAuthorValue("");
    }
  };

  const handleOnChangeAuthors = (newValue, context) => {
    if (context.action === "remove-value") {
      const removeAuthor = context.removedValue.value;
      setTempAuthors((authors) => authors.filter((v) => v !== removeAuthor));
    }
    setAuthorValue("");
  };

  const handleOnBlurAuthors = () => {
    if (authorValue && !tempAuthors.find((author) => author === authorValue)) {
      setTempAuthors((p) => [...p, authorValue]);
    }
    setAuthorValue("");
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Edit Paper Information</DialogTitle>
        <DialogDescription>
          Edit the paper title, author, and summary below.
        </DialogDescription>
      </DialogHeader>
      <div className="flex flex-col gap-y-4 text-sm">
        <div>
          <span className="font-semibold">Title</span>
          <Input
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
          />
        </div>
        <div>
          <span className="font-semibold">Authors</span>
          <CreatableSelect
            className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
            classNames={{
              container: ({ isFocused }) =>
                `${
                  isFocused
                    ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                    : ""
                }`,
            }}
            styles={{
              control: () => {},
            }}
            components={{
              DropdownIndicator: null,
              ClearIndicator: null,
            }}
            autoFocus
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleOnChangeAuthors}
            onInputChange={handleInputChangeAuthors}
            onKeyDown={handleKeyDownAuthors}
            onBlur={handleOnBlurAuthors}
            placeholder="Add an author..."
            inputValue={authorValue}
            value={tempAuthors.map((a) => createOption(a))}
          />
        </div>
        <div>
          <span className="font-semibold">Description</span>
          <Textarea
            className="h-64"
            value={tempSummary}
            onChange={(e) => setTempSummary(e.target.value)}
          />
        </div>
        <div className="flex justify-between">
          <button className="btn-secondary" onClick={handleOnClickCancel}>
            Cancel
          </button>
          <button
            className="btn-primary"
            disabled={
              name === tempTitle &&
              initial_text === tempSummary &&
              isAuthorsEqual
            }
            onClick={updatePaper}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
